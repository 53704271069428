import React from "react";
import { useNavigate } from "react-router-dom";

function SuccessModal({
  successModalVisible,
  contactEmail,
  userID,
  restaurantName,
  successTitle,
}) {
  const navigate = useNavigate();
  const randomPic = Math.random() * 4;
  return (
    <div
      className="successModalWhole"
      style={{ display: successModalVisible ? "inline" : "none" }}
    >
      <h2>
        {successTitle}{" "}
        <span className="navLogoDiv">
          {/* <a className="navLogo"> */}
          <img
            style={{
              width: "115px",
              paddingLeft: "7px",
              paddingRight: "0px",
              marginBottom: "-4px",
            }}
            src={require("../chompLogo.png")}
          />
          {/* </a> */}
        </span>
        !
      </h2>
      <p>
        We will reach out to{" "}
        <h4
          style={{
            display: "inline-block",
            marginBottom: "0px",
            marginTop: "0px",
          }}
        >
          {restaurantName}
        </h4>{" "}
        and let you know their decision as soon as possible at{" "}
        <h4
          style={{
            display: "inline-block",
            marginBottom: "0px",
            marginTop: "0px",
          }}
        >
          {contactEmail}
        </h4>
        !
      </p>
      <div className="successModalInner">
        <div className="successModalInnerInner">
          {randomPic > 3 ? (
            <img
              style={{ marginLeft: "6px" }}
              src={require("../chompSuccessModalGraphicOne.png")}
            />
          ) : randomPic > 2 ? (
            <img
              style={{ marginLeft: "24px" }}
              src={require("../chompSuccessModalGraphicTwo.png")}
            />
          ) : randomPic > 1 ? (
            <img
              style={{ marginLeft: "24px" }}
              src={require("../chompSuccessModalGraphicThree.png")}
            />
          ) : (
            <img
              style={{ marginLeft: "10px" }}
              src={require("../chompSuccessModalGraphicFour.png")}
            />
          )}
          {/* <div className="applyButton">
            <a target="blank" href={"/application"}>
              Return to Home
            </a>
          </div> */}
        </div>
      </div>
      <div className="applyButton">
        <a onClick={() => navigate(`/allPosts/${userID}`)}>Return to Posts</a>
      </div>

      {/* <button>Return to Home</button> */}
    </div>
  );
}

export default SuccessModal;
