import React, { useState, useEffect } from "react";
import "../styles/About.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCirclePlus,
  faAdd,
} from "@fortawesome/free-solid-svg-icons";
import { db, storage } from "../firebase-config";
import {
  getDocs,
  collection,
  where,
  query,
  doc,
  setDoc,
  addDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";

function About() {
  const navigate = useNavigate();
  const [inviteCodeError, setInviteCodeError] = useState(false);
  const [inviteCodeSubmitted, setInviteCodeSubmitted] = useState(null);
  const [inviteCodeAccepted, setInviteCodeAccepted] = useState(false);
  const [jobData, setJobData] = useState(null);
  const influencerArray = [
    "EL106",
    "ACH25",
    "AHO25",
    "ALE25",
    "ALL25",
    "ASK25",
    "BYH25",
    "CHC25",
    "CHU25",
    "CMA25",
    "CMC25",
    "CVI25",
    "DAM25",
    "EDA25",
    "EIE25",
    "EMI25",
    "FDD25",
    "JST25",
    "JUP25",
    "KAT25",
    "KME25",
    "KTC25",
    "LFT25",
    "LIT25",
    "LWH25",
    "MZE25",
    "MZH25",
    "NKH25",
    "RWN25",
    "SDL25",
    "SEN25",
    "SNA25",
    "SXW25",
    "TMC25",
  ];
  const restaurantArray = [
    "JY111",
    "ABB24",
    "AFT24",
    "AKI01",
    "ALN24",
    "AJB24",
    "AMA24",
    "ANP04",
    "APB28",
    "ARB24",
    "ARZ04",
    "ASH01",
    "ASL01",
    "ATM28",
    "AWP24",
    "BAC24",
    "BAI24",
    "BAN24",
    "BAN28",
    "BAT24",
    "BBB28",
    "BBE24",
    "BBU24",
    "BER24",
    "BES24",
    "BHB01",
    "BKS01",
    "BKT28",
    "BLO24",
    "BLP04",
    "BMT24",
    "BOD24",
    "BOT01",
    "BPI24",
    "BPC24",
    "BPZ04",
    "BRH01",
    "BRI24",
    "BRV04",
    "BSH01",
    "BSN28",
    "BSU24",
    "BTC24",
    "BUR28",
    "CAA01",
    "CAU28",
    "CBB01",
    "CBK24",
    "CBW01",
    "CCC24",
    "CHP04",
    "CLM24",
    "CMW24",
    "COK24",
    "COT01",
    "CPE24",
    "CPM24",
    "CRA24",
    "CQB24",
    "CVT24",
    "DAF04",
    "DEL24",
    "DOM24",
    "DZS24",
    "ECH01",
    "ENY04",
    "ERN01",
    "ESU01",
    "FAB24",
    "FCB01",
    "FIO24",
    "FIR24",
    "FHF01",
    "FLA24",
    "FLB28",
    "FRE24",
    "GAJ28",
    "GAP04",
    "GBP04",
    "GDA01",
    "GEP04",
    "GIP04",
    "GPH04",
    "GPI04",
    "GPR04",
    "GLP24",
    "GRI01",
    "GVK01",
    "HAP04",
    "HAR01",
    "HAZ24",
    "HOS24",
    "HIL01",
    "HJS01",
    "HPR01",
    "HRB01",
    "HSE28",
    "IHC24",
    "ISM01",
    "ILC24",
    "IMA24",
    "ISO24",
    "JAR24",
    "JOP04",
    "JOR24",
    "JUI01",
    "KAN24",
    "KAR01",
    "KAZ01",
    "KES01",
    "KKH24",
    "KOM24",
    "KPZ04",
    "KSB24",
    "KSS01",
    "KST24",
    "KYU24",
    "LBP04",
    "LIL01",
    "LIM01",
    "LFA24",
    "LLA24",
    "LLE24",
    "LLO24",
    "LON01",
    "LOQ24",
    "LSP04",
    "LTR24",
    "LUN28",
    "MAC04",
    "MAS01",
    "MAW28",
    "MBE24",
    "MDB24",
    "MLU01",
    "MMJ24",
    "MRB24",
    "MSE28",
    "NAP04",
    "NBB28",
    "NOP01",
    "NOP24",
    "NPZ04",
    "NRE24",
    "NRT24",
    "OCA24",
    "OJO01",
    "OTS24",
    "OSA24",
    "OSP04",
    "OSS01",
    "OTR24",
    "OUT24",
    "PAL01",
    "PAL24",
    "PEA24",
    "PCA24",
    "PCR01",
    "PDE24",
    "PHA24",
    "PHU24",
    "PIO04",
    "PIZ04",
    "PMB28",
    "PPU04",
    "PRE24",
    "PRO04",
    "PSQ04",
    "RAB28",
    "RAG24",
    "RPI01",
    "RRA24",
    "RRB24",
    "RRD28",
    "RTR24",
    "RYO01",
    "SAN01",
    "SBG28",
    "SCH24",
    "SDC01",
    "SHH04",
    "SHI24",
    "SHW01",
    "SOR24",
    "SPI24",
    "STV28",
    "SUP24",
    "SVS01",
    "TAK24",
    "TBI24",
    "TBR24",
    "TCO28",
    "TLC24",
    "TME28",
    "TMK28",
    "TMO01",
    "TPN04",
    "TPS24",
    "TRA24",
    "TRI04",
    "TSC28",
    "TST01",
    "TTS24",
    "UMM01",
    "UTR24",
    "UTT24",
    "VCA24",
    "VPI04",
    "WAT28",
    "WIL01",
    "WNM28",
    "WOP28",
    "WSF01",
    "WSP24",
    "YAS01",
    "ZAZ24",
    "ZGR24",
  ];

  const handleSelect = async (value) => {
    if (influencerArray.includes(inviteCodeSubmitted)) {
      setInviteCodeAccepted(true);
      // window.open(`/allposts/id=${inviteCodeSubmitted}`);
      // navigate(`/allposts/userID=${inviteCodeSubmitted}`);
      const enteredInviteCode = async () => {
        const docRef = await addDoc(collection(db, "invite_code_entered"), {
          invite_code: inviteCodeSubmitted,
          time_submitted_unix: Date.now(),
          timestamp: Date(Date.now()).toString(),
        });
      };
      enteredInviteCode();
      navigate(`/allposts/${inviteCodeSubmitted}`, {
        state: {
          invitedUserType: "creator",
        },
      });
    } else if (restaurantArray.includes(inviteCodeSubmitted)) {
      setInviteCodeAccepted(true);
      const enteredInviteCode = async () => {
        const docRef = await addDoc(collection(db, "invite_code_entered"), {
          invite_code: inviteCodeSubmitted,
          time_submitted_unix: Date.now(),
          timestamp: Date(Date.now()).toString(),
        });
      };
      enteredInviteCode();
      navigate(`/allposts/${inviteCodeSubmitted}`, {
        state: {
          invitedUserType: "business",
        },
      });
    } else {
      setInviteCodeError(true);
      value.preventDefault();
    }
  };

  // const submitInviteCode = async (value) => {
  //   setInviteCodeSubmitted(value);
  // };

  return (
    <div className="about" id="about">
      <div className="aboutInner">
        <div className="aboutInnerInner">
          <div
            className="aboutText"
            style={
              {
                // verticalAlign: "middle",
                // justifyContent: "center",
                // alignItems: "center",
                // backgroundColor: "red",
              }
            }
          >
            {/* <h1>
              Viral Marketing{" "}
              <span style={{ color: "white" }}>for Your Restaurant</span>
            </h1> */}
            <h1>
              Connect Restaurants <br />
              <span style={{ color: "white" }}>With Food Influencers</span>
            </h1>
            {/* <p>
          Collaborate with Tiktok and Instagram food influencers to reach your
          community! Create "Short Form Videos" for authentic, geographic
          content of your food. Set your own price and terms.{" "}
        </p> */}
            {/* <p>
          Reach your community with Collaborate with Tiktok and Instagram food influencers to reach your
          local community with authentic, geographic "Short Form Videos." Set
          your own terms, conditions, and price!
        </p> */}
            <p>
              Reach your community with engaging "Short Form Video" content.
              Collaborate with Tiktok and Instagram food influencers in your
              area on your own terms!
            </p>
            {/* <button>Request Code</button> */}
            <div className="aboutButtons">
              <div
                style={{
                  width: "max-content",
                  display: "inline-block",
                  marginLeft: "0px",
                  marginRight: "10px",
                }}
                className="addJobButton aboutButton"
              >
                <a
                  style={{
                    backgroundColor: "#ff7913",
                    color: "white",
                    border: "1px solid white",
                    paddingTop: "12px",
                    paddingBottom: "12px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    fontSize: "17px",
                  }}
                  href={"#requestcode"}
                >
                  Request Code
                </a>
              </div>
              <div
                style={{
                  width: "max-content",
                  display: "inline-block",
                }}
                className="addJobButton aboutButton"
              >
                <a
                  style={{
                    paddingTop: "12px",
                    paddingBottom: "12px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    fontSize: "17px",
                  }}
                  target="blank"
                  onClick={() => navigate(`/list`)}
                >
                  <FontAwesomeIcon
                    //   className="copyrightIcon"
                    icon={faAdd}
                  />{" "}
                  Add Job
                </a>
              </div>
            </div>
          </div>
          <div className="aboutGraphic">
            <img src={require("../392.png")} />
          </div>
        </div>
        <div
          style={{
            paddingTop: "0px",
          }}
          className="postsIntroForm"
        >
          <p
            style={{
              textAlign: "left",
              fontSize: "18px",
              // marginTop: "0px",
              // fontWeight: "500",
              marginLeft: "0px",
              maxWidth: "none",
              marginBottom: "20px",
            }}
          >
            Enter your unique code to view all postings.
          </p>
          <form onSubmit={(e) => handleSelect(e)}>
            <input
              onChange={(e) => setInviteCodeSubmitted(e.target.value)}
              value={inviteCodeSubmitted}
              placeholder="ABC123"
            />
            <button onClick={(e) => handleSelect(e)}>
              {" "}
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </form>
          <p
            style={{
              display: inviteCodeError ? "inline" : "none",
              color: "#ED4337",
              maxWidth: "none",
            }}
          >
            The invite code you entered is not correct, please try again.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
