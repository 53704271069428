import React, { useState, useEffect } from "react";
import "../styles/Post.css";
import "../styles/InfluencerApplyModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfluencerApplyModal from "../Pages/InfluencerApply";
import RestaurantImage from "../components/RestaurantImage";
import {
  faArrowRight,
  faCirclePlus,
  faAdd,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { db, storage } from "../firebase-config";
import {
  getDocs,
  collection,
  where,
  query,
  doc,
  setDoc,
  addDoc,
  orderBy,
} from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import { async } from "@firebase/util";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function AllPosts() {
  const navigate = useNavigate();
  const [jobData, setJobData] = useState(null);
  const [influencerData, setInfluencerData] = useState(null);
  const [isInvitedUser, setIsInvitedUser] = useState(null);
  const [invitedUserType, setInvitedUserType] = useState(null);
  const [invitedUserCode, setInvitedUserCode] = useState(null);

  const [influencerContentType, setInfluencerContentType] =
    useState("businesses");

  const [locationFilter, setLocationFilter] = useState("SF");
  const [cashMinFilter, setCashMinFilter] = useState(null);
  const [socialPlatformFilter, setSocialPlatformFilter] = useState(null);
  const [tiktokMinFollowers, setTiktokMinFollowers] = useState(null);
  const [instaMinFollowers, setInstaMinFollowers] = useState(null);

  const [locationFilterTemp, setLocationFilterTemp] = useState("SF");
  const [cashMinFilterTemp, setCashMinFilterTemp] = useState(null);
  const [socialPlatformFilterTemp, setSocialPlatformFilterTemp] =
    useState(null);
  const [tiktokMinFollowersTemp, setTiktokMinFollowersTemp] = useState(null);
  const [instaMinFollowersTemp, setInstaMinFollowersTemp] = useState(null);
  const location = useLocation();

  const influencerArray = [
    "EL106",
    "ACH25",
    "AHO25",
    "ALE25",
    "ALL25",
    "ASK25",
    "BYH25",
    "CHC25",
    "CHU25",
    "CMA25",
    "CMC25",
    "CVI25",
    "DAM25",
    "EDA25",
    "EIE25",
    "EMI25",
    "FDD25",
    "JST25",
    "JUP25",
    "KAT25",
    "KME25",
    "KTC25",
    "LFT25",
    "LIT25",
    "LWH25",
    "MZE25",
    "MZH25",
    "NKH25",
    "RWN25",
    "SDL25",
    "SEN25",
    "SNA25",
    "SXW25",
    "TMC25",
  ];
  const restaurantArray = [
    "JY111",
    "ABB24",
    "AFT24",
    "AKI01",
    "ALN24",
    "AJB24",
    "AMA24",
    "ANP04",
    "APB28",
    "ARB24",
    "ARZ04",
    "ASH01",
    "ASL01",
    "ATM28",
    "AWP24",
    "BAC24",
    "BAI24",
    "BAN24",
    "BAN28",
    "BAT24",
    "BBB28",
    "BBE24",
    "BBU24",
    "BER24",
    "BES24",
    "BHB01",
    "BKS01",
    "BKT28",
    "BLO24",
    "BLP04",
    "BMT24",
    "BOD24",
    "BOT01",
    "BPI24",
    "BPC24",
    "BPZ04",
    "BRH01",
    "BRI24",
    "BRV04",
    "BSH01",
    "BSN28",
    "BSU24",
    "BTC24",
    "BUR28",
    "CAA01",
    "CAU28",
    "CBB01",
    "CBK24",
    "CBW01",
    "CCC24",
    "CHP04",
    "CLM24",
    "CMW24",
    "COK24",
    "COT01",
    "CPE24",
    "CPM24",
    "CRA24",
    "CQB24",
    "CVT24",
    "DAF04",
    "DEL24",
    "DOM24",
    "DZS24",
    "ECH01",
    "ENY04",
    "ERN01",
    "ESU01",
    "FAB24",
    "FCB01",
    "FIO24",
    "FIR24",
    "FHF01",
    "FLA24",
    "FLB28",
    "FRE24",
    "GAJ28",
    "GAP04",
    "GBP04",
    "GDA01",
    "GEP04",
    "GIP04",
    "GPH04",
    "GPI04",
    "GPR04",
    "GLP24",
    "GRI01",
    "GVK01",
    "HAP04",
    "HAR01",
    "HAZ24",
    "HOS24",
    "HIL01",
    "HJS01",
    "HPR01",
    "HRB01",
    "HSE28",
    "IHC24",
    "ISM01",
    "ILC24",
    "IMA24",
    "ISO24",
    "JAR24",
    "JOP04",
    "JOR24",
    "JUI01",
    "KAN24",
    "KAR01",
    "KAZ01",
    "KES01",
    "KKH24",
    "KOM24",
    "KPZ04",
    "KSB24",
    "KSS01",
    "KST24",
    "KYU24",
    "LBP04",
    "LIL01",
    "LIM01",
    "LFA24",
    "LLA24",
    "LLE24",
    "LLO24",
    "LON01",
    "LOQ24",
    "LSP04",
    "LTR24",
    "LUN28",
    "MAC04",
    "MAS01",
    "MAW28",
    "MBE24",
    "MDB24",
    "MLU01",
    "MMJ24",
    "MRB24",
    "MSE28",
    "NAP04",
    "NBB28",
    "NOP01",
    "NOP24",
    "NPZ04",
    "NRE24",
    "NRT24",
    "OCA24",
    "OJO01",
    "OTS24",
    "OSA24",
    "OSP04",
    "OSS01",
    "OTR24",
    "OUT24",
    "PAL01",
    "PAL24",
    "PEA24",
    "PCA24",
    "PCR01",
    "PDE24",
    "PHA24",
    "PHU24",
    "PIO04",
    "PIZ04",
    "PMB28",
    "PPU04",
    "PRE24",
    "PRO04",
    "PSQ04",
    "RAB28",
    "RAG24",
    "RPI01",
    "RRA24",
    "RRB24",
    "RRD28",
    "RTR24",
    "RYO01",
    "SAN01",
    "SBG28",
    "SCH24",
    "SDC01",
    "SHH04",
    "SHI24",
    "SHW01",
    "SOR24",
    "SPI24",
    "STV28",
    "SUP24",
    "SVS01",
    "TAK24",
    "TBI24",
    "TBR24",
    "TCO28",
    "TLC24",
    "TME28",
    "TMK28",
    "TMO01",
    "TPN04",
    "TPS24",
    "TRA24",
    "TRI04",
    "TSC28",
    "TST01",
    "TTS24",
    "UMM01",
    "UTR24",
    "UTT24",
    "VCA24",
    "VPI04",
    "WAT28",
    "WIL01",
    "WNM28",
    "WOP28",
    "WSF01",
    "WSP24",
    "YAS01",
    "ZAZ24",
    "ZGR24",
  ];

  var hi = true;

  useEffect(() => {
    const hmm = window.location.href.substring(
      window.location.href.length - 5,
      window.location.href.length
    );
    if (influencerArray.includes(hmm)) {
      setIsInvitedUser(true);
      setInvitedUserCode(hmm);
      setInvitedUserType("creator");
      // const enteredInviteCode = async () => {
      //   const docRef = await addDoc(collection(db, "invite_code_entered"), {
      //     invite_code: hmm,
      //     time_submitted_unix: Date.now(),
      //     timestamp: Date(Date.now()).toString(),
      //   });
      // };
      // enteredInviteCode();
    } else if (restaurantArray.includes(hmm)) {
      setIsInvitedUser(true);
      setInvitedUserCode(hmm);
      setInvitedUserType("business");
    } else {
      setIsInvitedUser(false);
    }
  }, []);

  useEffect(() => {
    const qqq = query(
      collection(db, "job_postings"),
      orderBy("job_post_id", "desc")
    );
    const getJobPosts = async () => {
      const data = await getDocs(qqq);
      setJobData(
        data.docs
          // .sort((a, b) => ({
          //   ...(a.data().job_post_id - b.data().job_post_id),
          // }))
          .map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    };
    getJobPosts();
  }, []);

  useEffect(() => {
    const qqq = query(
      collection(db, "influencers")
      // orderBy("job_post_id", "desc")
    );
    const getInfluencers = async () => {
      const data = await getDocs(qqq);
      setInfluencerData(
        data.docs
          // .sort((a, b) => ({
          //   ...(a.data().job_post_id - b.data().job_post_id),
          // }))
          .map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    };
    getInfluencers();
  }, []);

  const applyJob = (
    jobID,
    restaurantName,
    restaurantYelp,
    restaurantLocation,
    restaurantDescription,
    restaurantImage,
    restaurantInfluencerCompensation,
    restaurantMediaType,
    restaurantSocialMediaPlatforms,
    restaurantJobDescription,
    tiktokFollowerRequirement,
    instagramFollowerRequirement,
    otherInfluencerRequirements
  ) => {
    navigate(`/application/${jobID}`, {
      state: {
        invitedUserCode,
        restaurantName,
        restaurantYelp,
        restaurantLocation,
        restaurantDescription,
        restaurantImage,
        restaurantInfluencerCompensation,
        restaurantMediaType,
        restaurantSocialMediaPlatforms,
        restaurantJobDescription,
        tiktokFollowerRequirement,
        instagramFollowerRequirement,
        otherInfluencerRequirements,
      },
    });
    // alert(restaurantName);
  };

  const inquireCreator = (
    // jobID,
    influencerID,
    influencerFirstName,
    influencerLocation,
    influencerHandle,
    influencerBio,
    influencerProfileImg,
    influencerTiktokFollowers,
    influencerTiktokLikes,
    influencerInstagramFollowers,
    exampleVideoOneLink,
    exampleVideoOneRestaurant,
    exampleVideoOneViews,
    exampleVideoTwoLink,
    exampleVideoTwoRestaurant,
    exampleVideoTwoViews,
    exampleVideoThreeLink,
    exampleVideoThreeRestaurant,
    exampleVideoThreeViews
  ) => {
    navigate(`/inquire/`, {
      state: {
        invitedUserCode,
        influencerID,
        influencerFirstName,
        influencerLocation,
        influencerHandle,
        influencerBio,
        influencerProfileImg,
        influencerTiktokFollowers,
        influencerTiktokLikes,
        influencerInstagramFollowers,
        exampleVideoOneLink,
        exampleVideoOneRestaurant,
        exampleVideoOneViews,
        exampleVideoTwoLink,
        exampleVideoTwoRestaurant,
        exampleVideoTwoViews,
        exampleVideoThreeLink,
        exampleVideoThreeRestaurant,
        exampleVideoThreeViews,
      },
    });
    // alert(restaurantName);
  };

  const searchFilterApply = () => {
    setCashMinFilter(cashMinFilterTemp);
    setSocialPlatformFilter(socialPlatformFilterTemp);
    setTiktokMinFollowers(tiktokMinFollowersTemp);
    setInstaMinFollowers(instaMinFollowersTemp);
  };

  return (
    <div className="postsWhole">
      {" "}
      {isInvitedUser && (
        <div className="jobPosts">
          <div style={{ backgroundColor: "#ff7913" }}>
            <form>
              <div
                style={{
                  width: "calc(86%)",
                  margin: "auto",
                  paddingTop: "10px",
                }}
              >
                <h2>Influencer Content</h2>
                {invitedUserType && invitedUserType === "business" && (
                  // ||
                  //   location.state.invitedUserType === "business"
                  <label class="switch">
                    <input type="checkbox" id="togBtn" />
                    <div
                      onClick={() => {
                        influencerContentType === "businesses"
                          ? setInfluencerContentType("creators")
                          : setInfluencerContentType("businesses");
                      }}
                      class="slider round"
                    >
                      <span class="off">Businesses</span>
                      <span class="on">Creators</span>
                    </div>
                  </label>
                )}
              </div>
            </form>
          </div>

          {influencerContentType === "businesses" && (
            <div>
              <div style={{ backgroundColor: "white" }}>
                <form
                  className="postFilters postFiltersLarge"
                  style={{
                    paddingBottom: "20px",
                    paddingTop: "25px",
                  }}
                >
                  <div style={{ width: "max-content", margin: "auto" }}>
                    <div>
                      <div
                        style={{ display: "inline-block", marginRight: "10px" }}
                      >
                        <h4>Location</h4>
                        <select>
                          <option>San Francisco Bay Area</option>
                        </select>
                      </div>
                      <div
                        style={{ display: "inline-block", marginRight: "10px" }}
                      >
                        <h4>Cash Min.</h4>
                        <input
                          onChange={(e) => setCashMinFilterTemp(e.target.value)}
                        />
                      </div>
                      <div style={{ display: "inline-block" }}>
                        <h4>Social Platform</h4>
                        <select
                          onChange={(e) => {
                            setSocialPlatformFilterTemp(e.target.value);
                          }}
                        >
                          <option value="Tiktok">Tiktok</option>
                          <option value="Reels">Reels</option>
                          <option value="Tiktok and Reels">
                            Tiktok and Reels
                          </option>
                        </select>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{ display: "inline-block", marginRight: "10px" }}
                      >
                        <h4>Min. Tiktok Followers</h4>
                        <input
                          onChange={(e) =>
                            setTiktokMinFollowersTemp(e.target.value)
                          }
                        />
                      </div>
                      <div style={{ display: "inline-block" }}>
                        <h4>Min. Insta Followers</h4>
                        <input
                          onChange={(e) =>
                            setInstaMinFollowersTemp(e.target.value)
                          }
                        />
                      </div>
                      <button type="button" onClick={() => searchFilterApply()}>
                        Search
                      </button>
                    </div>
                  </div>
                </form>
                <form
                  className="postFilters postFiltersSmall"
                  style={{
                    paddingBottom: "20px",
                    paddingTop: "25px",
                  }}
                >
                  <div style={{ width: "max-content", margin: "auto" }}>
                    <div>
                      <div
                        style={{ display: "inline-block", marginRight: "10px" }}
                      >
                        <h4>Location</h4>
                        <select>
                          <option>San Francisco Bay Area</option>
                        </select>
                      </div>
                      <div style={{ display: "inline-block" }}>
                        <h4>Cash Min.</h4>
                        <input
                          onChange={(e) => setCashMinFilterTemp(e.target.value)}
                        />
                      </div>
                    </div>
                    <div>
                      <div
                        style={{ display: "inline-block", marginRight: "10px" }}
                      >
                        <h4>Social Platform</h4>
                        <select
                          onChange={(e) => {
                            setSocialPlatformFilterTemp(e.target.value);
                          }}
                        >
                          <option value="Tiktok">Tiktok</option>
                          <option value="Reels">Reels</option>
                          <option value="Tiktok and Reels">
                            Tiktok and Reels
                          </option>
                        </select>
                      </div>
                      <div style={{ display: "inline-block" }}>
                        <h4>Min. Tiktok Followers</h4>
                        <input
                          onChange={(e) =>
                            setTiktokMinFollowersTemp(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <div style={{ display: "inline-block" }}>
                        <h4>Min. Insta Followers</h4>
                        <input
                          onChange={(e) =>
                            setInstaMinFollowersTemp(e.target.value)
                          }
                        />
                      </div>
                      <button type="button" onClick={() => searchFilterApply()}>
                        Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="posts">
                {
                  jobData &&
                    jobData.map((post, index) => {
                      return (
                        (!tiktokMinFollowers ||
                          !post.tiktok_follower_requirement ||
                          parseInt(post.tiktok_follower_requirement) <=
                            tiktokMinFollowers) &&
                        (!instaMinFollowers ||
                          !post.instagram_follower_requirement ||
                          parseInt(post.instagram_follower_requirement) <=
                            instaMinFollowers) &&
                        (!cashMinFilter ||
                          parseInt(post.influencer_compensation) >=
                            cashMinFilter) &&
                        (!socialPlatformFilter ||
                          (socialPlatformFilter === "Tiktok" &&
                            post.social_media_platforms.includes("Tiktok") &&
                            !post.social_media_platforms.includes("and")) ||
                          (socialPlatformFilter === "Reels" &&
                            post.social_media_platforms.includes("Reels") &&
                            !post.social_media_platforms.includes("and")) ||
                          (socialPlatformFilter === "Tiktok and Reels" &&
                            post.social_media_platforms.includes("and"))) && (
                          <div
                            className="post"
                            // index={post.job_post_id}
                          >
                            <div className="postInner">
                              <div className="postTitle">
                                <a target="_blank" href={post.restaurant_yelp}>
                                  <h3>{post.restaurant_name}</h3>
                                </a>
                                <p className="postRestaurantLocation">
                                  {post.restaurant_location}
                                </p>
                              </div>
                              <p className="postRestaurantDescription">
                                {post.restaurant_description}
                              </p>
                              <div className="boo">
                                <div className="postRestaurantImage">
                                  <RestaurantImage
                                    restaurantImage={post.restaurant_image}
                                  />
                                </div>
                                <div className="postJobDetails">
                                  <p className="postCompensation">
                                    ${post.influencer_compensation} + Comped
                                    Meal
                                  </p>
                                  <div className="postJobType">
                                    <h4>{post.media_type}:</h4>
                                    <div className="postJobPlatforms">
                                      {post.social_media_platforms &&
                                      post.social_media_platforms.includes(
                                        "Tiktok"
                                      ) ? (
                                        <p className="socialMediaTag tiktokTag">
                                          Tiktok
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                      {post.social_media_platforms &&
                                      post.social_media_platforms[1] ? (
                                        <p
                                          style={{
                                            marginLeft: "5px",
                                            marginRight: "5px",
                                          }}
                                        >
                                          {post.social_media_platforms[1]}
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                      {post.social_media_platforms &&
                                      post.social_media_platforms.includes(
                                        "Reels"
                                      ) ? (
                                        <p className="socialMediaTag reelsTag">
                                          Reels
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                  <div className="postJobDescription">
                                    <h4>Job Description:</h4>
                                    <p>{post.job_description}</p>
                                  </div>
                                  <div className="postJobRequirements">
                                    <h4>Requirements:</h4>
                                    {post.tiktok_follower_requirement && (
                                      <p>
                                        {post.tiktok_follower_requirement}+
                                        followers on Tiktok
                                        <br />
                                      </p>
                                    )}
                                    {post.instagram_follower_requirement && (
                                      <p>
                                        {post.instagram_follower_requirement}+
                                        followers on Instagram
                                        <br />
                                      </p>
                                    )}
                                    {post.other_influencer_requirements && (
                                      <p>
                                        {post.other_influencer_requirements}
                                        <br />
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="postApplyButton">
                                <a
                                  onClick={(e) =>
                                    applyJob(
                                      post.job_post_id,
                                      post.restaurant_name,
                                      post.restaurant_yelp,
                                      post.restaurant_location,
                                      post.restaurant_description,
                                      post.restaurant_image,
                                      post.influencer_compensation,
                                      post.media_type,
                                      post.social_media_platforms,
                                      post.job_description,
                                      post.tiktok_follower_requirement,
                                      post.instagram_follower_requirement,
                                      post.other_influencer_requirements
                                    )
                                  }
                                >
                                  Apply
                                </a>
                              </div>
                            </div>
                          </div>
                        )
                      );
                      // });
                    })
                  // .sort(post.job_post_id)
                }
              </div>
            </div>
          )}

          {influencerContentType === "creators" && (
            <div>
              <div style={{ backgroundColor: "white" }}>
                {/* <form
                  className="postFilters"
                  style={{
                    paddingBottom: "20px",
                    paddingTop: "25px",
                  }}
                >
                  <div style={{ width: "max-content", margin: "auto" }}>
                    <div>
                      <div
                        style={{ display: "inline-block", marginRight: "10px" }}
                      >
                        <h4>Location</h4>
                        <select>
                          <option>San Francisco Bay Area</option>
                        </select>
                      </div>
                      <div
                        style={{ display: "inline-block", marginRight: "10px" }}
                      >
                        <h4>Cash Min.</h4>
                        <input
                          onChange={(e) => setCashMinFilterTemp(e.target.value)}
                        />
                      </div>
                      <div style={{ display: "inline-block" }}>
                        <h4>Social Platform</h4>
                        <select
                          onChange={(e) => {
                            setSocialPlatformFilterTemp(e.target.value);
                          }}
                        >
                          <option value="Tiktok">Tiktok</option>
                          <option value="Reels">Reels</option>
                          <option value="Tiktok and Reels">
                            Tiktok and Reels
                          </option>
                        </select>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{ display: "inline-block", marginRight: "10px" }}
                      >
                        <h4>Tiktok Min. Followers</h4>
                        <input
                          onChange={(e) =>
                            setTiktokMinFollowersTemp(e.target.value)
                          }
                        />
                      </div>
                      <div style={{ display: "inline-block" }}>
                        <h4>Instagram Min. Followers</h4>
                        <input
                          onChange={(e) =>
                            setInstaMinFollowersTemp(e.target.value)
                          }
                        />
                      </div>
                      <button type="button" onClick={() => searchFilterApply()}>
                        Search
                      </button>
                    </div>
                  </div>
                </form> */}
              </div>
              <div className="posts">
                {
                  influencerData &&
                    influencerData.map((influencer, index) => {
                      return (
                        // (!tiktokMinFollowers ||
                        //   !post.tiktok_follower_requirement ||
                        //   parseInt(post.tiktok_follower_requirement) <=
                        //     tiktokMinFollowers) &&
                        // (!instaMinFollowers ||
                        //   !post.instagram_follower_requirement ||
                        //   parseInt(post.instagram_follower_requirement) <=
                        //     instaMinFollowers) &&
                        // (!cashMinFilter ||
                        //   parseInt(post.influencer_compensation) >=
                        //     cashMinFilter) &&
                        // ((socialPlatformFilter === "Tiktok" &&
                        //   post.social_media_platforms.includes("Tiktok") &&
                        //   !post.social_media_platforms.includes("and")) ||
                        //   (socialPlatformFilter === "Reels" &&
                        //     post.social_media_platforms.includes("Reels") &&
                        //     !post.social_media_platforms.includes("and")) ||
                        //   (socialPlatformFilter === "Tiktok and Reels" &&
                        //     post.social_media_platforms.includes("and"))) &&
                        <div className="post">
                          <div className="postInner">
                            <div className="postTitle">
                              <a
                                target="_blank"
                                // href={influencer.restaurant_yelp}
                              >
                                <h3>{influencer.first_name}</h3>
                              </a>
                              <p className="postRestaurantLocation">
                                {influencer.influencer_location}
                              </p>
                            </div>
                            <span>
                              <p className="influencerBioHandle">
                                <h4
                                  style={{
                                    display: "inline-block",
                                    marginBottom: "0px",
                                  }}
                                  className="postRestaurantDescription"
                                >
                                  {influencer.influencer_handle}
                                </h4>{" "}
                                {influencer.influencer_bio}
                              </p>
                            </span>
                            <p></p>
                            <div className="boo">
                              <div className="postRestaurantImage">
                                <RestaurantImage
                                  restaurantImage={
                                    influencer.influencer_profile_img
                                  }
                                />
                              </div>
                              <div className="postJobDetails postJobDetailsCreator">
                                {/* <p className="postCompensationCreator">
                                    Contact for Details
                                  </p> */}
                                <div>
                                  {/* <h4>Followers:</h4> */}
                                  <div className="postJobPlatformsCreator">
                                    <table
                                      className="followerDetails"
                                      style={{
                                        // backgroundColor: "pink",
                                        width: "100%",
                                        maxWidth: "500px",
                                        // border: "1px solid rgb(230, 230, 230)"
                                      }}
                                    >
                                      <tr>
                                        <td
                                          style={{
                                            width: "10%",
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            // backgroundColor: "yellow",
                                            // width: "calc(50% - 40px)",
                                            width: "calc(40%)",
                                          }}
                                        >
                                          <p
                                            style={{
                                              textAlign: "center",
                                              marginBottom: "0px",
                                              marginTop: "0px",
                                            }}
                                          >
                                            followers
                                          </p>
                                        </td>
                                        <td
                                          style={
                                            {
                                              // backgroundColor: "yellow",
                                              // width: "calc(50% - 40px)",
                                            }
                                          }
                                        >
                                          <p
                                            style={{
                                              textAlign: "center",
                                              marginBottom: "0px",
                                              marginTop: "0px",
                                            }}
                                          >
                                            likes
                                          </p>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <p
                                            style={{ marginBottom: "0px" }}
                                            className="socialMediaTag tiktokTag"
                                          >
                                            Tiktok
                                          </p>
                                        </td>
                                        <td
                                          style={
                                            {
                                              // backgroundColor: "yellow",
                                              // width: "calc(50% - 40px)",
                                            }
                                          }
                                        >
                                          <div
                                            style={{
                                              textAlign: "center",
                                              // width: "100%",
                                            }}
                                            className="socialMediaTag socialMetricTag"
                                          >
                                            <p style={{ width: "100%" }}>
                                              {
                                                influencer.influencer_tiktok_followers
                                              }
                                            </p>
                                          </div>
                                        </td>
                                        <td
                                          style={
                                            {
                                              // backgroundColor: "yellow",
                                              // width: "calc(50% - 40px)",
                                            }
                                          }
                                        >
                                          <div
                                            style={{
                                              textAlign: "center",
                                              // width: "100%",
                                            }}
                                            className="socialMediaTag socialMetricTag"
                                          >
                                            <p style={{ width: "100%" }}>
                                              {
                                                influencer.influencer_tiktok_likes
                                              }
                                            </p>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <p className="socialMediaTag reelsTag">
                                            Reels
                                          </p>
                                        </td>
                                        <td
                                          style={
                                            {
                                              // backgroundColor: "yellow",
                                              // width: "calc(50% - 40px)",
                                            }
                                          }
                                        >
                                          <div
                                            style={{
                                              textAlign: "center",
                                              marginTop: "5px",
                                              // width: "100%",
                                            }}
                                            className="socialMediaTag socialMetricTag"
                                          >
                                            <p
                                              style={{
                                                width: "100%",
                                              }}
                                            >
                                              {
                                                influencer.influencer_instagram_followers
                                              }
                                            </p>
                                          </div>
                                        </td>
                                        <td
                                          style={{
                                            // backgroundColor: "yellow",
                                            width: "calc(40%)",
                                          }}
                                        >
                                          {/* <p className="socialMediaTag reelsTag">
                                              Reels
                                            </p> */}
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                                <div className="postJobDescription">
                                  <h4>Example Videos:</h4>
                                  <div>
                                    <div>
                                      <a
                                        target="_blank"
                                        href={influencer.example_video_one_link}
                                        className="exampleVideoLink"
                                      >
                                        {
                                          influencer.example_video_one_restaurant
                                        }
                                      </a>{" "}
                                      <p style={{ display: "inline-block" }}>
                                        ({influencer.example_video_one_views}{" "}
                                        views)
                                      </p>
                                    </div>
                                    <div>
                                      <a
                                        target="_blank"
                                        href={influencer.example_video_two_link}
                                        className="exampleVideoLink"
                                      >
                                        {
                                          influencer.example_video_two_restaurant
                                        }
                                      </a>{" "}
                                      <p style={{ display: "inline-block" }}>
                                        ({influencer.example_video_two_views}{" "}
                                        views)
                                      </p>
                                    </div>
                                    <div>
                                      <a
                                        target="_blank"
                                        href={
                                          influencer.example_video_three_link
                                        }
                                        className="exampleVideoLink"
                                      >
                                        {
                                          influencer.example_video_three_restaurant
                                        }
                                      </a>{" "}
                                      <p style={{ display: "inline-block" }}>
                                        ({influencer.example_video_three_views}{" "}
                                        views)
                                      </p>
                                    </div>
                                  </div>
                                  {/* <p>{post.job_description}</p> */}
                                </div>
                                {/* <div className="postJobRequirements">
                                    <h4>Requirements:</h4>
                                    {post.tiktok_follower_requirement && (
                                      <p>
                                        {post.tiktok_follower_requirement}+
                                        followers on Tiktok
                                        <br />
                                      </p>
                                    )}
                                    {post.instagram_follower_requirement && (
                                      <p>
                                        {post.instagram_follower_requirement}+
                                        followers on Instagram
                                        <br />
                                      </p>
                                    )}
                                    {post.other_influencer_requirements && (
                                      <p>
                                        {post.other_influencer_requirements}
                                        <br />
                                      </p>
                                    )}
                                  </div> */}
                              </div>
                            </div>
                            <div className="postApplyButton">
                              <a
                                onClick={(e) =>
                                  inquireCreator(
                                    influencer.influencer_id,
                                    influencer.first_name,
                                    influencer.influencer_location,
                                    influencer.influencer_handle,
                                    influencer.influencer_bio,
                                    influencer.influencer_profile_img,
                                    influencer.influencer_tiktok_followers,
                                    influencer.influencer_tiktok_likes,
                                    influencer.influencer_instagram_followers,
                                    influencer.example_video_one_link,
                                    influencer.example_video_one_restaurant,
                                    influencer.example_video_one_views,
                                    influencer.example_video_two_link,
                                    influencer.example_video_two_restaurant,
                                    influencer.example_video_two_views,
                                    influencer.example_video_three_link,
                                    influencer.example_video_three_restaurant,
                                    influencer.example_video_three_views
                                  )
                                }
                              >
                                Inquire
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                      // });
                    })
                  // .sort(post.job_post_id)
                }
              </div>
            </div>
          )}
        </div>
      )}
      {!isInvitedUser && (
        <div>
          <p
            style={{
              paddingLeft: "calc(5% + 13px)",
              paddingRight: "calc(5% + 13px)",
            }}
          >
            You did not enter your correct invite code. Please try again or
            request a interest code.
          </p>
        </div>
      )}
    </div>
  );
}

export default AllPosts;
