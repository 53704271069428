import React, { useState, useEffect } from "react";
import PostJobSuccessModal from "./PostJobSuccessModal";
import { db, storage } from "../firebase-config";
import {
  getDocs,
  collection,
  where,
  query,
  doc,
  setDoc,
  addDoc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { v4 } from "uuid";

function RestaurantListJob() {
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [contactEmail, setContactEmail] = useState(null);
  const [restaurantName, setRestaurantName] = useState(null);
  const [restaurantYelp, setRestaurantYelp] = useState(null);
  const [restaurantLocation, setRestaurantLocation] =
    useState("San Francisco, CA");
  const [restaurantDescription, setRestaurantDescription] = useState(null);
  const [restaurantImageUpload, setRestaurantImageUpload] = useState(null);
  const [influencerPayment, setInfluencerPayment] = useState(null);
  const [influencerCompedMeal, setInfluencerCompedMeal] = useState(null);
  const [socialPlatforms, setSocialPlatforms] = useState("Tiktok");
  const [jobDescription, setJobDescription] = useState(null);
  const [minTiktokFollowers, setMinTiktokFollowers] = useState(null);
  const [minInstagramFollowers, setMinInstagramFollowers] = useState(null);
  const [otherRequirements, setOtherRequirements] = useState(null);

  const [submitError, setSubmitError] = useState(null);
  const [emailError, setEmailError] = useState(null);

  const [jobPostsTotal, setJobPostsTotal] = useState(null);

  // const [imageRefV44, setImageRefV44] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);

  // const [tiktokProfile, setTiktokProfile] = useState(null);
  // const [instagramProfile, setInstagramProfile] = useState(null);
  // const [exampleWork, setExampleWork] = useState(null);
  // const [other, setOther] = useState(null);

  const [successModalVisible, setSuccessModalVisible] = useState(false);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    // alert("t");
    if (jobPostsTotal) {
      const getUserVerified = async () => {
        await updateDoc(doc(db, "job_postings_info", "jkDRwfN7eMa6ufF2sfAl"), {
          job_postings_total: jobPostsTotal + 1,
        });
      };
      // alert("j");
      getUserVerified();
      if (restaurantImageUpload == null) {
        setSubmitError(true);
        return;
      } else {
        const imageRefV4 = v4();
        // setImageRefV44(imageRefV4);
        const imageRef = ref(storage, `restaurant_images/${imageRefV4}`);
        uploadBytes(imageRef, restaurantImageUpload).then((snapshot) => {
          getDownloadURL(snapshot.ref).then((url) => {
            setImageUrls((prev) => [...prev, url]);
          });
        });
        if (
          firstName &&
          lastName &&
          contactEmail &&
          restaurantName &&
          restaurantYelp &&
          restaurantLocation &&
          restaurantDescription &&
          imageRefV4 &&
          influencerPayment &&
          influencerCompedMeal &&
          socialPlatforms &&
          jobDescription
        ) {
          const addJobPosting = async () => {
            const docRef = await addDoc(collection(db, "job_postings"), {
              media_type: "Short Form Video",
              owner_first_name: firstName,
              owner_last_name: lastName,
              contact_email: contactEmail,
              restaurant_name: restaurantName,
              restaurant_yelp: restaurantYelp,
              restaurant_location: restaurantLocation,
              restaurant_description: restaurantDescription,
              restaurant_image: imageRefV4,
              influencer_compensation: influencerPayment,
              influencer_comped_meal: influencerCompedMeal,
              social_media_platforms:
                socialPlatforms === "Tiktok"
                  ? ["Tiktok"]
                  : socialPlatforms === "Reels"
                  ? ["Reels"]
                  : socialPlatforms === "Tiktok and Reels"
                  ? ["Tiktok", "and", "Reels"]
                  : socialPlatforms === "Tiktok or Reels"
                  ? ["Tiktok", "or", "Reels"]
                  : null,
              job_description: jobDescription,
              tiktok_follower_requirement: minTiktokFollowers,
              instagram_follower_requirement: minInstagramFollowers,
              other_influencer_requirements: otherRequirements,
              // job_post_id: jobPostsTotal ? jobPostsTotal + 100 : null,
              job_post_id: jobPostsTotal + 100 + 1,
              time_submitted_unix: Date.now(),
              time_submitted: Date(Date.now()).toString(),
            });
          };
          addJobPosting();
          setSuccessModalVisible(true);
        } else {
          setSubmitError(true);
        }
      }
    }
  }, [jobPostsTotal]);

  function getPostId() {
    // var totalPosts = null;
    // const getUserVerified = async () => {
    //   const qqqq = await getDoc(doc(db, "job_postings", "job_postings_info"));
    //   console.log(qqqq.data());
    //   if (qqqq) {
    //     totalPosts = qqqq.get("total_posts");
    //     setJobPostsTotal(totalPosts + 1);
    //     return totalPosts + 1;
    //   }
    // };
    // getUserVerified();
    // }
  }

  const handleSelect = async (value) => {
    value.preventDefault();
    if (
      firstName &&
      lastName &&
      contactEmail &&
      restaurantName &&
      restaurantYelp &&
      restaurantLocation &&
      restaurantDescription &&
      restaurantImageUpload &&
      influencerPayment &&
      influencerCompedMeal &&
      socialPlatforms &&
      jobDescription
      // &&
      // (minTiktokFollowers || minInstagramFollowers)
    ) {
      if (validateEmail(contactEmail)) {
        // alert("k");
        var totalPosts = null;
        const getUserVerified = async () => {
          const qqqq = await getDoc(
            doc(db, "job_postings_info", "jkDRwfN7eMa6ufF2sfAl")
          );
          console.log(qqqq.data());
          if (qqqq) {
            // alert("z");
            totalPosts = qqqq.get("job_postings_total");
            setJobPostsTotal(totalPosts);
            // alert(totalPosts);
            // return totalPosts + 1;
          }
        };
        getUserVerified();
        // //upload image to storage
        /////
      } else {
        setEmailError(true);
      }
    } else {
      setSubmitError(true);
    }
  };

  return (
    <div className="influencerApply">
      <PostJobSuccessModal
        successModalVisible={successModalVisible}
        contactEmail={contactEmail}
      />
      <h2>Add Your Job</h2>
      {/* <p className="applicationJobDescription">
    To create a 30 second to 1 minute stitched video with voiceover
    showcasing [Restaurant Name]. All partnerships include a free meal
    within the restaurant's specified price point.
  </p> */}
      <p className="applicationJobDescription">
        {/* List your restaurant for influencers to create geographically targeted
        marketing.  */}
        The influencer payment and comped meal should total more than $50.
        {/* (All jobs include a free meal
            at your specified price point). */}
        {/* All partnerships include a free meal within the
        restaurant's specified price point. */}
      </p>

      <div className="influencerApplyForm">
        <form>
          {/* <FontAwesomeIcon
        icon={faXmark}
      />{" "} */}

          <div className="applicationInputs">
            <div className="applicationInputsLeft">
              <h4>Owner First Name *</h4>
              <input
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setSubmitError(null);
                }}
                placeholder="John"
              />
              <h4>Owner Last Name *</h4>
              <input
                onChange={(e) => {
                  setLastName(e.target.value);
                  setSubmitError(null);
                }}
                placeholder="Doe"
              />
              <h4>Contact Email *</h4>
              <input
                onChange={(e) => {
                  setContactEmail(e.target.value);
                  setSubmitError(null);
                  setEmailError(null);
                }}
              />
              <h4>Restaurant Name *</h4>
              <input
                onChange={(e) => {
                  setRestaurantName(e.target.value);
                  setSubmitError(null);
                }}
                placeholder=""
              />
              <h4>Restaurant Yelp *</h4>
              <input
                onChange={(e) => {
                  setRestaurantYelp(e.target.value);
                  setSubmitError(null);
                }}
                placeholder="www.yelp.com/biz/"
              />
              <h4>Restaurant Location *</h4>
              <select>
                <option value="San Francisco, CA">San Francisco, CA</option>
              </select>
              <h4>Restaurant Description *</h4>
              <textarea
                onChange={(e) => {
                  setRestaurantDescription(e.target.value);
                  setSubmitError(null);
                }}
                placeholder="1-3 sentences introducing your restaurant."
              ></textarea>
            </div>
            <div className="applicationInputsRight">
              <h4>Restaurant Image *</h4>
              <input
                onChange={(event) => {
                  setRestaurantImageUpload(event.target.files[0]);
                  setSubmitError(null);
                }}
                style={{
                  border: "none",
                  paddingTop: "4px",
                  paddingLeft: "0px",
                }}
                type="file"
                placeholder="URL"
              />
              <h4>Influencer Payment *{/* ($50 min) */}</h4>
              <span
                style={{
                  position: "absolute",
                  paddingTop: "8px",
                  paddingLeft: "8px",
                }}
                class="prefix"
              >
                $
              </span>
              <input
                onChange={(e) => {
                  setInfluencerPayment(e.target.value);
                  setSubmitError(null);
                }}
                placeholder="100"
                style={{
                  paddingLeft: "23px",
                  width: "calc(100% - 35px)",
                }}
              />
              <h4>Influencer Comped Meal *</h4>
              <span
                style={{
                  position: "absolute",
                  paddingTop: "8px",
                  paddingLeft: "8px",
                }}
                class="prefix"
              >
                $
              </span>
              <input
                // onChange={(e) => setInfluencerPayment(e.target.value)}
                onChange={(e) => {
                  setInfluencerCompedMeal(e.target.value);
                  setSubmitError(null);
                }}
                placeholder="50"
                style={{
                  paddingLeft: "23px",
                  width: "calc(100% - 35px)",
                }}
              />
              {/* <p>
                In addition to one free meal within your specified price point.
              </p> */}
              <h4>Which Social Platforms? *</h4>
              <select
                onChange={(e) => {
                  setSocialPlatforms(e.target.value);
                  setSubmitError(null);
                }}
              >
                <option value="Tiktok">Tiktok</option>
                <option value="Reels">Reels</option>
                <option value="Tiktok and Reels">Tiktok and Reels</option>
                <option value="Tiktok or Reels">Tiktok or Reels</option>
              </select>
              <h4>Job Description *</h4>
              <textarea
                onChange={(e) => {
                  setJobDescription(e.target.value);
                  setSubmitError(null);
                }}
                placeholder="1-3 sentences explaining what you want influencers to showcase."
              ></textarea>
              <h4>Influencer Min. Followers (Tiktok)</h4>
              <input
                onChange={(e) => {
                  setMinTiktokFollowers(e.target.value);
                  setSubmitError(null);
                }}
                placeholder="1000"
              />
              <h4>Influencer Min. Followers (Instagram)</h4>
              <input
                onChange={(e) => {
                  setMinInstagramFollowers(e.target.value);
                  setSubmitError(null);
                }}
                placeholder="1000"
              />
              <h4>Other Requirements</h4>
              <textarea
                onChange={(e) => {
                  setOtherRequirements(e.target.value);
                  setSubmitError(null);
                }}
                placeholder="Is there anything else you would want influencers to have?"
              ></textarea>
            </div>
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <p
              style={{
                display: submitError ? "inline" : "none",
                color: "#ED4337",
                maxWidth: "none",
              }}
            >
              You may have missed a field, please try again.
            </p>
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <p
              style={{
                display: emailError ? "inline" : "none",
                color: "#ED4337",
                maxWidth: "none",
              }}
            >
              The email you have entered is not valid, please try again.
            </p>
          </div>
          <button
            onClick={(e) => handleSelect(e)}
            // onClick={(e) => alert(socialPlatforms)}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default RestaurantListJob;
