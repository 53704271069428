import React from "react";
import RestaurantImage from "./RestaurantImage";

function Post({
  restaurantName,
  restaurantYelp,
  restaurantLocation,
  restaurantDescription,
  restaurantImage,
  restaurantInfluencerCompensation,
  restaurantMediaType,
  restaurantSocialMediaPlatforms,
  restaurantJobDescription,
  tiktokFollowerRequirement,
  instagramFollowerRequirement,
  otherInfluencerRequirements,
}) {
  return (
    <div style={{ border: "1px solid rgb(230, 230, 230)" }} className="post">
      <div className="postInner">
        <div className="postTitle">
          <a target="_blank" href={restaurantYelp}>
            <h3>{restaurantName}</h3>
          </a>
          <p className="postRestaurantLocation">{restaurantLocation}</p>
        </div>
        <p className="postRestaurantDescription">{restaurantDescription}</p>
        <div className="boo">
          <div className="postRestaurantImage">
            <RestaurantImage restaurantImage={restaurantImage} />
          </div>
          <div className="postJobDetails">
            <p className="postCompensation">
              ${restaurantInfluencerCompensation} + Comped Meal
            </p>
            <div className="postJobType">
              <h4>{restaurantMediaType}:</h4>
              <div className="postJobPlatforms">
                {restaurantSocialMediaPlatforms &&
                restaurantSocialMediaPlatforms.includes("Tiktok") ? (
                  <p className="socialMediaTag tiktokTag">Tiktok</p>
                ) : (
                  <></>
                )}
                {restaurantSocialMediaPlatforms &&
                restaurantSocialMediaPlatforms[1] ? (
                  <p>{restaurantSocialMediaPlatforms[1]}</p>
                ) : (
                  <></>
                )}
                {restaurantSocialMediaPlatforms &&
                restaurantSocialMediaPlatforms.includes("Reels") ? (
                  <p className="socialMediaTag reelsTag">Reels</p>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="postJobDescription">
              <h4>Job Description:</h4>
              <p>{restaurantJobDescription}</p>
            </div>
            <div className="postJobRequirements">
              <h4>Requirements:</h4>
              {tiktokFollowerRequirement && (
                <p>
                  {tiktokFollowerRequirement}+ followers on Tiktok
                  <br />
                </p>
              )}
              {instagramFollowerRequirement && (
                <p>
                  {instagramFollowerRequirement}+ followers on Instagram
                  <br />
                </p>
              )}
              {otherInfluencerRequirements && (
                <p>
                  {otherInfluencerRequirements}
                  <br />
                </p>
              )}
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Post;
