import React, { useState, useEffect } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { db, storage } from "../firebase-config";
import {
  getDocs,
  collection,
  where,
  query,
  doc,
  setDoc,
  addDoc,
} from "firebase/firestore";

function RequestCode() {
  const [requestFormType, setRequestFormType] = useState("business");
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [contactEmail, setContactEmail] = useState(null);
  const [yelpProfile, setYelpProfile] = useState(null);
  const [tiktokProfile, setTiktokProfile] = useState(null);
  const [instagramProfile, setInstagramProfile] = useState(null);

  // const [errorRequestCode, setErrorRequestCode] = useState("none");

  const [submitError, setSubmitError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [socialMediaError, setSocialMediaError] = useState(null);

  const [requestCodeSubmitted, setRequestCodeSubmitted] = useState("none");

  const [testRestaurantPhone, setTestRestaurantPhone] =
    useState("+1 415-519-6138");
  const [sentVerificationCode, setSentVerificationCode] = useState(null);
  const [receivedVerificationCode, setReceivedVerificationCode] =
    useState(null);

  const functions = getFunctions();

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleSelect = async (value) => {
    value.preventDefault();
    // alert(firstName);
    if (firstName && lastName && contactEmail) {
      if (validateEmail(contactEmail)) {
        if (requestFormType === "business" && yelpProfile) {
          // window.open(
          //   `mailto:ujennyyu@gmail.com?subject=RequestFormCode:${requestFormType}body=FirstName:${firstName}&&&LastName:${lastName}&&&ContactEmail:${contactEmail}&&&YelpProfile:${yelpProfile}`
          // );
          const addJobPosting = async () => {
            const docRef = await addDoc(collection(db, "request_code"), {
              user_type: requestFormType,
              first_name: firstName,
              last_name: lastName,
              contact_email: contactEmail,
              business_yelp_link: yelpProfile,
              time_submitted_unix: Date.now(),
              timestamp: Date(Date.now()).toString(),
            });
          };
          addJobPosting();
          // setErrorRequestCode("none");
          setSubmitError(false);
          setRequestCodeSubmitted("block");
          document.getElementById("requestCodeForm").reset();
          setFirstName(null);
          setLastName(null);
          setContactEmail(null);
          setYelpProfile(null);
          setInstagramProfile(null);
          setTiktokProfile(null);
          // window.location.href =
          //   "mailto:ujennyyu@gmail.com" +
          //   "&subject=" +
          //   encodeURIComponent(`RequestFormCode:${requestFormType}`) +
          //   "&body=" +
          //   encodeURIComponent(
          //     `FirstName:${firstName}&LastName:${lastName}&ContactEmail:${contactEmail}&YelpProfile:${yelpProfile}`
          //   );
          // window.location.href.open(
          //   `mailto:ujennyyu@gmail.com?subject=RequestFormCode:${requestFormType}body=FirstName:${firstName}&&&LastName:${lastName}&&&ContactEmail:${contactEmail}&&&YelpProfile:${yelpProfile}`
          // );
        } else if (requestFormType === "influencer") {
          if (tiktokProfile || instagramProfile) {
            const addJobPosting = async () => {
              const docRef = await addDoc(collection(db, "request_code"), {
                user_type: requestFormType,
                first_name: firstName,
                last_name: lastName,
                contact_email: contactEmail,
                influencer_tiktok_link: tiktokProfile,
                influencer_instagram_link: instagramProfile,
                time_submitted_unix: Date.now(),
                time_submitted: Date(Date.now()).toString(),
              });
            };
            addJobPosting();
            // setErrorRequestCode("none");
            setSubmitError(false);
            setRequestCodeSubmitted("block");
            document.getElementById("requestCodeForm").reset();
            setFirstName(null);
            setLastName(null);
            setContactEmail(null);
            setYelpProfile(null);
            setInstagramProfile(null);
            setTiktokProfile(null);
          } else {
            setSocialMediaError(true);
          }
        } else {
          // setErrorRequestCode("block");
          setSubmitError(true);
        }
      } else {
        setEmailError(true);
      }
    } else {
      // setErrorRequestCode("block");
      setSubmitError(true);
    }
  };

  const sendText = (_) => {
    var result = "";

    const sendTextFirebase = httpsCallable(functions, "sendText");

    if (!sentVerificationCode) {
      // alert("set verification code");
      var characters = "0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < 5; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      setSentVerificationCode(result);

      sendTextFirebase({
        recipient: `${testRestaurantPhone
          .replace(/-/g, "")
          .replace(/\s/g, "")}`,
        textmessage: `Your Starfruit Verification Code is: ${result}`,
      }).then(
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      sendTextFirebase({
        recipient: `${testRestaurantPhone
          .replace(/-/g, "")
          .replace(/\s/g, "")}`,
        textmessage: `Your Starfruit Verification Code is: ${sentVerificationCode}`,
      }).then(
        (res) => {
          console.log(res);
          const data = res.data;
          const recipient = data.recipient;
          const textmessage = data.textmessage;
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  return (
    <div
      style={
        {
          // backgroundImage:
          //   "linear-gradient(45deg, #edaa1b 0%, #ff8f2c 50%, #ff5c2c 100%)",
        }
      }
      className="influencerApply"
      id="requestcode"
    >
      <h2
      // style={{ color: "white" }}
      >
        Request a
        <span className="navLogoDiv">
          {/* <a className="navLogo" href="/"> */}
          <img
            style={{
              width: "115px",
              paddingLeft: "8px",
              paddingRight: "8px",
              marginBottom: "-3px",
            }}
            src={require("../chompLogo.png")}
          />
          {/* </a> */}
        </span>
        Invite Code
      </h2>
      <p className="applicationJobDescription">
        We will contact you within two business days. Thank you for your
        patience. (Influencers need only provide one of their relevant social
        profiles).
      </p>
      <div className="influencerApplyForm">
        {/* <p
          style={{
            paddingLeft: "calc(5% + 13px)",
            paddingRight: "calc(5% + 13px)",
            marginTop: "-5px",
            marginBottom: "20px",
            color: "rgb(237, 67, 55)",

            display: errorRequestCode,
          }}
        >
          You may have missed a field, please try again.
        </p> */}
        <form id="requestCodeForm">
          {/* <label class="switch">
            <input type="checkbox" />
            <span class="slider"></span>
            <span class="on">ON</span>
            <span class="off">OFF</span>
          </label> */}
          <label class="switch">
            <input type="checkbox" id="togBtn" />
            <div
              onClick={() => {
                requestFormType === "business"
                  ? setRequestFormType("influencer")
                  : setRequestFormType("business");
                setRequestCodeSubmitted("none");
                setSocialMediaError(false);
              }}
              class="slider round"
            >
              <span class="off">Business</span>
              <span class="on">Influencer</span>
            </div>
          </label>
          <h4>First Name *</h4>
          <input
            onChange={(e) => {
              setFirstName(e.target.value);
              setSubmitError(false);
              setRequestCodeSubmitted("none");
              // requestCodeSubmitted === "block"
              //   ? setRequestCodeSubmitted("none")
              //   : null;
            }}
            placeholder="John"
          />
          <h4>Last Name *</h4>
          <input
            onChange={(e) => {
              setLastName(e.target.value);
              setSubmitError(false);
              setRequestCodeSubmitted("none");
              // requestCodeSubmitted === "block"
              //   ? setRequestCodeSubmitted("none")
              //   : null;
            }}
            placeholder="Doe"
          />
          <h4>Contact Email *</h4>
          <input
            onChange={(e) => {
              setContactEmail(e.target.value);
              setSubmitError(false);
              setEmailError(false);
              setRequestCodeSubmitted("none");
              // requestCodeSubmitted === "block"
              //   ? setRequestCodeSubmitted("none")
              //   : null;
            }}
            // placeholder="John"
          />
          {requestFormType === "business" && (
            <>
              <h4>Business Yelp Link *</h4>
              <input
                onChange={(e) => {
                  setYelpProfile(e.target.value);
                  setSubmitError(false);
                  setRequestCodeSubmitted("none");
                  // requestCodeSubmitted === "block"
                  //   ? setRequestCodeSubmitted("none")
                  //   : null;
                }}
                placeholder="www.yelp.com/"
              />
            </>
          )}
          {requestFormType === "influencer" && (
            <>
              <h4>Tiktok Profile Link</h4>
              <input
                onChange={(e) => {
                  setTiktokProfile(e.target.value);
                  setSubmitError(false);
                  setRequestCodeSubmitted("none");
                  setSocialMediaError(false);
                  // requestCodeSubmitted === "block"
                  //   ? setRequestCodeSubmitted("none")
                  //   : null;
                }}
                placeholder="www.tiktok.com/"
              />
              <h4>Instagram Profile Link</h4>
              <input
                onChange={(e) => {
                  setInstagramProfile(e.target.value);
                  setSubmitError(false);
                  setRequestCodeSubmitted("none");
                  setSocialMediaError(false);
                  // requestCodeSubmitted === "block"
                  //   ? setRequestCodeSubmitted("none")
                  //   : null;
                }}
                placeholder="www.instagram.com/"
              />
            </>
          )}
          <div style={{ width: "100%", textAlign: "center" }}>
            <p
              style={{
                display: submitError ? "inline" : "none",
                color: "#ED4337",
                maxWidth: "none",
              }}
            >
              You may have missed a field, please try again.
            </p>
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <p
              style={{
                display: emailError ? "inline" : "none",
                color: "#ED4337",
                maxWidth: "none",
              }}
            >
              The email you have entered is not valid, please try again.
            </p>
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <p
              style={{
                display: socialMediaError ? "inline" : "none",
                color: "#ED4337",
                maxWidth: "none",
              }}
            >
              Please provide at least one social media profile link and try
              again.
            </p>
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <p
              style={{
                color: "gray",
                maxWidth: "none",
                display: requestCodeSubmitted,
              }}
            >
              Your invite code request has been submitted, thank you!
            </p>
          </div>
          <button onClick={(e) => handleSelect(e)}>Submit</button>
        </form>
      </div>
    </div>
  );
}

export default RequestCode;
