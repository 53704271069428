import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "../styles/SuccessfulExamples.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

function SuccessfulExamples() {
  return (
    <div className="successfulExamplesWhole">
      <Swiper
        speed={1500}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper successfulExamples"
        slidesPerView={1}
        loop={true}
      >
        <SwiperSlide>
          <div className="successfulExampleSlideOne">
            <h2>
              <span style={{ fontWeight: "900" }}>Millions</span>{" "}
              <span
                style={
                  {
                    // color: "white",
                  }
                }
              >
                follow Food Influencers{" "}
              </span>
              {/* <span style={{ color: "gray" }}>across the United States!</span> */}
            </h2>
            <div className="intro">
              <p className="introText">
                Everyone wants to learn about food and dining experiences in
                their city. That's why influencers are taking up the challenge
                on Tiktok and Reels to share good eats.
              </p>
            </div>
            <div className="slideOneImagesLarge">
              <img src={require("../foodtiktokersnewnew.png")} />
            </div>
            <div className="slideOneImagesSmall">
              <div style={{ width: "100%" }}>
                <img
                  id="slideOneImagesSmallOne"
                  src={require("../foodtiktokertim.png")}
                />
              </div>
              <div style={{ width: "100%" }}>
                <img
                  id="slideOneImagesSmallTwo"
                  src={require("../foodtiktokerkeith.png")}
                />
              </div>
              <div style={{ width: "100%" }}>
                <img
                  id="slideOneImagesSmallThree"
                  src={require("../foodtiktokersoy.png")}
                />
              </div>
            </div>
            {/* <p className="disclaimer">
            *These influencers are not associated with [Company Name] and serve
            only as example for the necessity of explorative food content.
          </p> */}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="successfulExampleSlideSecond">
            <h2>
              {/* <span style={{ fontWeight: "900" }}>Millions</span>{" "} */}
              <span style={{ color: "gray" }}>Example Bay Area </span>
              <span style={{ fontWeight: "900" }}>
                Successful Food Campaigns
              </span>
              {/* <span style={{ color: "gray" }}>across the United States!</span> */}
            </h2>
            <div className="intro">
              <p className="introText">
                Social Media is now an important way people find food
                recommendations. One successful video can encourage others to
                visit and share their own experience.
              </p>
            </div>
            {/* <div className="intro">
            <p className="introText">
              <span style={{ fontWeight: "800" }}>Pineapple King Bakery</span>{" "}
              picked up virality on tiktok after people got wind of their butter
              pineapple buns. Now PK has lines out their doors everday of the
              week. <br />
              <span style={{ fontWeight: "800" }}>Square Pie Guys</span> has
              been popular on social media, but really received a boost in Bay
              Area customers after tiktok users began recommeding the
              establishment in their top restaurant lists.
            </p>
          </div> */}

            <div className="successfulExampleSlideSecondInner">
              <div className="successfulExampleCase successfulExampleCaseOne">
                <img src={require("../tiktokcaseonenew.png")} />
                <p className="caseText">
                  <span style={{ fontWeight: "800" }}>
                    Pineapple King Bakery
                  </span>{" "}
                  gained virality for their butter pineapple buns, and now sees
                  lines out their doors every day.
                </p>
              </div>
              <div className="successfulExampleCase successfulExampleCaseTwo">
                <img src={require("../tiktokcasetwonew.png")} />
                <p className="caseText">
                  <span style={{ fontWeight: "800" }}>Square Pie Guys</span>{" "}
                  received a boost in the Bay Area after Tiktokers added them
                  their top restaurant lists.
                </p>
              </div>
            </div>
            {/* <p className="disclaimer">
            *These influencers and restaurants are not associated with [Company
            Name] and serve only as example for the necessity of explorative
            food content.
          </p> */}
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default SuccessfulExamples;
