import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { db } from "../firebase-config";
import { useLocation } from "react-router-dom";
import {
  getDocs,
  collection,
  where,
  query,
  doc,
  setDoc,
  addDoc,
} from "firebase/firestore";
import SuccessModal from "./SuccessModal";
import "../styles/SuccessModal.css";
import Post from "../components/Post";
import RestaurantImage from "../components/RestaurantImage";

function InquireCreator(
  {
    // restaurantName,
    // restaurantYelp,
    // restaurantLocation,
    // restaurantDescription,
    // restaurantImage,
    // restaurantInfluencerCompensation,
    // restaurantMediaType,
    // restaurantSocialMediaPlatforms,
    // restaurantJobDescription,
    // tiktokFollowerRequirement,
    // instagramFollowerRequirement,
    // otherInfluencerRequirements,
    // state,
  }
) {
  // const jobPostId = 0;
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [contactEmail, setContactEmail] = useState(null);
  const [businessName, setBusinessName] = useState(null);
  const [yelpProfile, setYelpProfile] = useState(null);
  const [jobDescription, setJobDescription] = useState(null);
  const location = useLocation();

  const [jobPostId, setJobPostId] = useState(null);

  const [submitError, setSubmitError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [socialMediaError, setSocialMediaError] = useState(null);

  const [successModalVisible, setSuccessModalVisible] = useState(false);

  useEffect(() => {
    const hmm = window.location.href.substring(
      window.location.href.length - 3,
      window.location.href.length
    );
    setJobPostId(hmm);
  }, []);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleSelect = async (value) => {
    value.preventDefault();
    if (
      firstName &&
      lastName &&
      businessName &&
      yelpProfile &&
      jobDescription &&
      contactEmail
    ) {
      if (validateEmail(contactEmail)) {
        const addJobPosting = async () => {
          const docRef = await addDoc(collection(db, "inquiry_creator"), {
            business_first_name: firstName,
            business_last_name: lastName,
            contact_email: contactEmail,
            user_id: location.state.invitedUserCode,
            influencer_id: location.state.influencerID,
            restaurant_name: businessName,
            yelp_profile: yelpProfile,
            job_description: jobDescription,
            time_submitted: Date(Date.now()).toString(),
            time_submitted_unix: Date.now(),
          });
        };
        addJobPosting();
        setSuccessModalVisible(true);
      } else {
        setEmailError(true);
      }
    } else {
      setSubmitError(true);
    }
  };
  return (
    <div className="influencerApply">
      <SuccessModal
        successModalVisible={successModalVisible}
        contactEmail={contactEmail}
        userID={location.state.invitedUserCode}
        restaurantName={location.state.influencerFirstName}
        successTitle={"Congrats, On Inquiring With"}
      />
      <h2>Inquire Creator</h2>
      <p className="applicationJobDescription">
        Describe briefly with
        {/* with voiceover  */}{" "}
        <h4
          style={{
            display: "inline-block",
            marginBottom: "0px",
            marginTop: "0px",
          }}
        >
          {location.state.influencerFirstName}
        </h4>{" "}
        what you hope to make with them.
      </p>

      <div style={{ border: "1px solid rgb(230, 230, 230)" }} className="post">
        <div className="postInner">
          <div className="postTitle">
            <a
              target="_blank"
              // href={influencer.restaurant_yelp}
            >
              <h3>{location.state.influencerFirstName}</h3>
            </a>
            <p className="postRestaurantLocation">
              {location.state.influencerLocation}
            </p>
          </div>
          <span>
            <p className="influencerBioHandle">
              <h4
                style={{
                  display: "inline-block",
                  marginBottom: "0px",
                }}
                className="postRestaurantDescription"
              >
                {location.state.influencerHandle}
              </h4>{" "}
              {location.state.influencerBio}
            </p>
          </span>
          <p></p>
          <div className="boo">
            <div className="postRestaurantImage">
              <RestaurantImage
                restaurantImage={location.state.influencerProfileImg}
              />
            </div>
            <div className="postJobDetails postJobDetailsCreator">
              {/* <p className="postCompensationCreator">
                                    Contact for Details
                                  </p> */}
              <div>
                {/* <h4>Followers:</h4> */}
                <div className="postJobPlatformsCreator">
                  <table
                    className="followerDetails"
                    style={{
                      // backgroundColor: "pink",
                      width: "100%",
                      maxWidth: "500px",
                      // border: "1px solid rgb(230, 230, 230)"
                    }}
                  >
                    <tr>
                      <td
                        style={{
                          width: "10%",
                        }}
                      ></td>
                      <td
                        style={{
                          // backgroundColor: "yellow",
                          // width: "calc(50% - 40px)",
                          width: "calc(40%)",
                        }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            marginBottom: "0px",
                            marginTop: "0px",
                          }}
                        >
                          followers
                        </p>
                      </td>
                      <td>
                        <p
                          style={{
                            textAlign: "center",
                            marginBottom: "0px",
                            marginTop: "0px",
                          }}
                        >
                          likes
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p
                          style={{ marginBottom: "0px" }}
                          className="socialMediaTag tiktokTag"
                        >
                          Tiktok
                        </p>
                      </td>
                      <td>
                        <div
                          style={{
                            textAlign: "center",
                          }}
                          className="socialMediaTag socialMetricTag"
                        >
                          <p style={{ width: "100%" }}>
                            {location.state.influencerTiktokFollowers}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            textAlign: "center",
                          }}
                          className="socialMediaTag socialMetricTag"
                        >
                          <p style={{ width: "100%" }}>
                            {location.state.influencerTiktokLikes}
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="socialMediaTag reelsTag">Reels</p>
                      </td>
                      <td>
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "5px",
                          }}
                          className="socialMediaTag socialMetricTag"
                        >
                          <p
                            style={{
                              width: "100%",
                            }}
                          >
                            {location.state.influencerInstagramFollowers}
                          </p>
                        </div>
                      </td>
                      <td
                        style={{
                          width: "calc(40%)",
                        }}
                      ></td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="postJobDescription">
                <h4>Example Videos:</h4>
                <div>
                  <div>
                    <a
                      target="_blank"
                      href={location.state.exampleVideoOneLink}
                      className="exampleVideoLink"
                    >
                      {location.state.exampleVideoOneRestaurant}
                    </a>{" "}
                    <p style={{ display: "inline-block" }}>
                      ({location.state.exampleVideoOneViews} views)
                    </p>
                  </div>
                  <div>
                    <a
                      target="_blank"
                      href={location.state.exampleVideoTwoLink}
                      className="exampleVideoLink"
                    >
                      {location.state.exampleVideoTwoRestaurant}
                    </a>{" "}
                    <p style={{ display: "inline-block" }}>
                      ({location.state.exampleVideoTwoViews} views)
                    </p>
                  </div>
                  <div>
                    <a
                      target="_blank"
                      href={location.state.exampleVideoThreeLink}
                      className="exampleVideoLink"
                    >
                      {location.state.exampleVideoThreeRestaurant}
                    </a>{" "}
                    <p style={{ display: "inline-block" }}>
                      ({location.state.exampleVideoThreeViews} views)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="influencerApplyForm">
        <form>
          <div className="applicationInputs">
            <div className="applicationInputsLeft">
              <h4>First Name *</h4>
              <input
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setSubmitError(false);
                }}
                placeholder="John"
              />
              <h4>Last Name *</h4>
              <input
                onChange={(e) => {
                  setLastName(e.target.value);
                  setSubmitError(false);
                }}
                placeholder="Doe"
              />
              <h4>Contact Email *</h4>
              <input
                onChange={(e) => {
                  setContactEmail(e.target.value);
                  setSubmitError(false);
                  setEmailError(false);
                }}
              />
              <h4>Business Name *</h4>
              <input
                onChange={(e) => {
                  setBusinessName(e.target.value);
                  setSubmitError(false);
                }}
                // placeholder="www.tiktok.com/"
              />
            </div>
            <div className="applicationInputsRight">
              <h4>Yelp Business Profile *</h4>
              <input
                onChange={(e) => {
                  setYelpProfile(e.target.value);
                  setSubmitError(false);
                }}
                placeholder="www.yelp.com/"
              />
              <h4>Job Description *</h4>
              <textarea
                onChange={(e) => {
                  setJobDescription(e.target.value);
                  setSubmitError(false);
                }}
                placeholder="What are you hoping to have made?"
              ></textarea>
            </div>
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <p
              style={{
                display: submitError ? "inline" : "none",
                color: "#ED4337",
                maxWidth: "none",
              }}
            >
              You may have missed a field, please try again.
            </p>
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <p
              style={{
                display: emailError ? "inline" : "none",
                color: "#ED4337",
                maxWidth: "none",
              }}
            >
              The email you have entered is not valid, please try again.
            </p>
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <p
              style={{
                display: socialMediaError ? "inline" : "none",
                color: "#ED4337",
                maxWidth: "none",
              }}
            >
              Please provide at least one social media profile link and try
              again.
            </p>
          </div>
          <button onClick={(e) => handleSelect(e)}>Submit</button>
        </form>
      </div>
    </div>
  );
}

export default InquireCreator;
