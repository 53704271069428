import React, { useState } from "react";
import About from "./About";
import Posts from "./Posts";
import SuccessfulExamples from "./SuccessfulExamples";
import RequestCode from "./RequestCode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCirclePlus,
  faAdd,
  faBars,
} from "@fortawesome/free-solid-svg-icons";

function Home() {
  return (
    <div>
      {/* <div
        style={{
          position: "fixed",
          backgroundColor: "rgba(10, 10, 10, .5)",
          display: sideMenuVisible,
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          zIndex: "90",
        }}
      ></div> */}

      <About />
      <SuccessfulExamples />
      <Posts />
      <RequestCode />
    </div>
  );
}

export default Home;
