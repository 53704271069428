import React, { useState, useEffect } from "react";
import "../styles/Post.css";
import "../styles/InfluencerApplyModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfluencerApplyModal from "../Pages/InfluencerApply";
import {
  faArrowRight,
  faCirclePlus,
  faAdd,
} from "@fortawesome/free-solid-svg-icons";

import { db, storage } from "../firebase-config";
import {
  getDocs,
  collection,
  where,
  query,
  doc,
  setDoc,
  addDoc,
} from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import { async } from "@firebase/util";

function RestaurantImage({ restaurantImage }) {
  const [rest, setRest] = useState(null);
  const [restt, setRestt] = useState(null);
  const restaurantImagee = ref(storage, `restaurant_images/${restaurantImage}`);

  const getPostImgSrc = async (postImg) => {
    //... firebase code here
    const res = await getDownloadURL(restaurantImagee);
    setRestt(res);
    return res;
    // getDownloadURL(restaurantImagee).then((url) => {
    //   setRestt(url);
    //   return url;
    // });
  };

  useEffect(() => {
    async function callFunction() {
      const url = await getPostImgSrc(restaurantImage);
      setRest(url);
    }
    callFunction();
    // getDownloadURL(restaurantImagee).then((url) => {
    //   setRestt(url);
    //   return url;
    // });
    // getDownloadURL();
  }, []);

  //   useEffect(async () => {
  //     const restaurantImagee = ref(
  //       storage,
  //       `restaurant_images/${restaurantImage}`
  //     );
  //     const res = await getDownloadURL(restaurantImagee);
  //     setRest(res);
  //   }, []);

  return (
    <div>
      {restt && (
        // <img src={require(restt)} />
        <>
          {/* <p>{restt}</p>
          <p>{rest}</p> */}
          {/* <img src={require(restt)} /> */}
          <img
            // style={{ width: "500px" }}
            src={restt}
          />
        </>
      )}
    </div>
  );
}

export default RestaurantImage;
