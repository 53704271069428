// import logo from "./logo.svg";
import "./App.css";
// import logo from "./logo.svg";
import "./App.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Posts from "./Pages/Posts";
import Home from "./Pages/Home";
import AllPosts from "./Pages/AllPosts";
import InfluencerApply from "./Pages/InfluencerApply";
import InquireCreator from "./Pages/InquireCreator";
import RestaurantListJob from "./Pages/RestaurantListJob";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          {/* <Route path="/projects" exact element={<Projects />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/allposts/:id" element={<AllPosts />} />
          <Route path="/application/:id" element={<InfluencerApply />} />
          <Route path="/inquire/" element={<InquireCreator />} />
          <Route path="/list" element={<RestaurantListJob />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
