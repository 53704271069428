import React, { useState, useEffect } from "react";
import "../styles/Post.css";
import "../styles/InfluencerApplyModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfluencerApplyModal from "../Pages/InfluencerApply";
import RestaurantImage from "../components/RestaurantImage";
import {
  faArrowRight,
  faCirclePlus,
  faAdd,
} from "@fortawesome/free-solid-svg-icons";

import { db, storage } from "../firebase-config";
import {
  getDocs,
  collection,
  where,
  query,
  doc,
  setDoc,
  addDoc,
} from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import { async } from "@firebase/util";
import { useNavigate } from "react-router-dom";

function Posts() {
  const navigate = useNavigate();
  const [inviteCodeError, setInviteCodeError] = useState(false);
  const [inviteCodeSubmitted, setInviteCodeSubmitted] = useState(null);
  const [inviteCodeAccepted, setInviteCodeAccepted] = useState(false);
  const [jobData, setJobData] = useState(null);
  const influencerArray = [
    "EL106",
    "ACH25",
    "AHO25",
    "ALE25",
    "ALL25",
    "ASK25",
    "BYH25",
    "CHC25",
    "CHU25",
    "CMA25",
    "CMC25",
    "CVI25",
    "DAM25",
    "EDA25",
    "EIE25",
    "EMI25",
    "FDD25",
    "JST25",
    "JUP25",
    "KAT25",
    "KME25",
    "KTC25",
    "LFT25",
    "LIT25",
    "LWH25",
    "MZE25",
    "MZH25",
    "NKH25",
    "RWN25",
    "SDL25",
    "SEN25",
    "SNA25",
    "SXW25",
    "TMC25",
  ];
  const restaurantArray = [
    "JY111",
    "ABB24",
    "AFT24",
    "AKI01",
    "ALN24",
    "AJB24",
    "AMA24",
    "ANP04",
    "APB28",
    "ARB24",
    "ARZ04",
    "ASH01",
    "ASL01",
    "ATM28",
    "AWP24",
    "BAC24",
    "BAI24",
    "BAN24",
    "BAN28",
    "BAT24",
    "BBB28",
    "BBE24",
    "BBU24",
    "BER24",
    "BES24",
    "BHB01",
    "BKS01",
    "BKT28",
    "BLO24",
    "BLP04",
    "BMT24",
    "BOD24",
    "BOT01",
    "BPI24",
    "BPC24",
    "BPZ04",
    "BRH01",
    "BRI24",
    "BRV04",
    "BSH01",
    "BSN28",
    "BSU24",
    "BTC24",
    "BUR28",
    "CAA01",
    "CAU28",
    "CBB01",
    "CBK24",
    "CBW01",
    "CCC24",
    "CHP04",
    "CLM24",
    "CMW24",
    "COK24",
    "COT01",
    "CPE24",
    "CPM24",
    "CRA24",
    "CQB24",
    "CVT24",
    "DAF04",
    "DEL24",
    "DOM24",
    "DZS24",
    "ECH01",
    "ENY04",
    "ERN01",
    "ESU01",
    "FAB24",
    "FCB01",
    "FIO24",
    "FIR24",
    "FHF01",
    "FLA24",
    "FLB28",
    "FRE24",
    "GAJ28",
    "GAP04",
    "GBP04",
    "GDA01",
    "GEP04",
    "GIP04",
    "GPH04",
    "GPI04",
    "GPR04",
    "GLP24",
    "GRI01",
    "GVK01",
    "HAP04",
    "HAR01",
    "HAZ24",
    "HOS24",
    "HIL01",
    "HJS01",
    "HPR01",
    "HRB01",
    "HSE28",
    "IHC24",
    "ISM01",
    "ILC24",
    "IMA24",
    "ISO24",
    "JAR24",
    "JOP04",
    "JOR24",
    "JUI01",
    "KAN24",
    "KAR01",
    "KAZ01",
    "KES01",
    "KKH24",
    "KOM24",
    "KPZ04",
    "KSB24",
    "KSS01",
    "KST24",
    "KYU24",
    "LBP04",
    "LIL01",
    "LIM01",
    "LFA24",
    "LLA24",
    "LLE24",
    "LLO24",
    "LON01",
    "LOQ24",
    "LSP04",
    "LTR24",
    "LUN28",
    "MAC04",
    "MAS01",
    "MAW28",
    "MBE24",
    "MDB24",
    "MLU01",
    "MMJ24",
    "MRB24",
    "MSE28",
    "NAP04",
    "NBB28",
    "NOP01",
    "NOP24",
    "NPZ04",
    "NRE24",
    "NRT24",
    "OCA24",
    "OJO01",
    "OTS24",
    "OSA24",
    "OSP04",
    "OSS01",
    "OTR24",
    "OUT24",
    "PAL01",
    "PAL24",
    "PEA24",
    "PCA24",
    "PCR01",
    "PDE24",
    "PHA24",
    "PHU24",
    "PIO04",
    "PIZ04",
    "PMB28",
    "PPU04",
    "PRE24",
    "PRO04",
    "PSQ04",
    "RAB28",
    "RAG24",
    "RPI01",
    "RRA24",
    "RRB24",
    "RRD28",
    "RTR24",
    "RYO01",
    "SAN01",
    "SBG28",
    "SCH24",
    "SDC01",
    "SHH04",
    "SHI24",
    "SHW01",
    "SOR24",
    "SPI24",
    "STV28",
    "SUP24",
    "SVS01",
    "TAK24",
    "TBI24",
    "TBR24",
    "TCO28",
    "TLC24",
    "TME28",
    "TMK28",
    "TMO01",
    "TPN04",
    "TPS24",
    "TRA24",
    "TRI04",
    "TSC28",
    "TST01",
    "TTS24",
    "UMM01",
    "UTR24",
    "UTT24",
    "VCA24",
    "VPI04",
    "WAT28",
    "WIL01",
    "WNM28",
    "WOP28",
    "WSF01",
    "WSP24",
    "YAS01",
    "ZAZ24",
    "ZGR24",
  ];

  const [restaurantImages, setRestaurantImages] = useState();

  // useEffect(async () => {
  //   const restaurantImages = ref(storage, "restaurant_images");
  //   setRestaurantImages(restaurantImages);
  // }, []);

  // const getPostImgSrc = async (postImg) => {
  //   const imgRef = ref(storage, `restaurant_images/${postImg}`);
  //   const res = await getDownloadURL(imgRef);
  //   return res;
  // };

  // useEffect(async () => {
  //   const restaurantImages = ref(storage, "restaurant_images");
  //   // const res = await getDownloadURL(restaurantImages);
  //   // setRestaurantImages(res);
  //   console.log(restaurantImages);
  // }, []);

  const handleSelect = async (value) => {
    if (influencerArray.includes(inviteCodeSubmitted)) {
      setInviteCodeAccepted(true);
      // window.open(`/allposts/id=${inviteCodeSubmitted}`);
      // navigate(`/allposts/userID=${inviteCodeSubmitted}`);
      const enteredInviteCode = async () => {
        const docRef = await addDoc(collection(db, "invite_code_entered"), {
          invite_code: inviteCodeSubmitted,
          time_submitted_unix: Date.now(),
          timestamp: Date(Date.now()).toString(),
        });
      };
      enteredInviteCode();
      navigate(`/allposts/${inviteCodeSubmitted}`, {
        state: {
          invitedUserType: "creator",
        },
      });
    } else if (restaurantArray.includes(inviteCodeSubmitted)) {
      setInviteCodeAccepted(true);
      const enteredInviteCode = async () => {
        const docRef = await addDoc(collection(db, "invite_code_entered"), {
          invite_code: inviteCodeSubmitted,
          time_submitted_unix: Date.now(),
          timestamp: Date(Date.now()).toString(),
        });
      };
      enteredInviteCode();
      navigate(`/allposts/${inviteCodeSubmitted}`, {
        state: {
          invitedUserType: "business",
        },
      });
    } else {
      setInviteCodeError(true);
      value.preventDefault();
    }
  };

  // useEffect(() => {
  //   const qqq = query(collection(db, "job_postings"));
  //   const getJobPosts = async () => {
  //     const data = await getDocs(qqq);
  //     setJobData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  //   };
  //   getJobPosts();
  // }, []);

  return (
    <div id="jobpostings">
      <div
        style={{
          // backgroundImage:
          //   "linear-gradient(45deg,  #ff5c2c 0%,#edaa1b 50%,#ff8f2c 100%)",
          backgroundColor: "#f3f3f3",
          borderBottom: "1px solid rgb(230 230 230)",
          paddingBottom: "40px",
        }}
      >
        <div
          style={{
            paddingTop: "40px",
            // paddingBottom: "60px",
          }}
          className="postsIntroForm"
        >
          <h2>
            Invited
            {/* {" "} */}
            <span className="navLogoDiv">
              {/* <a className="navLogo"> */}
              <img
                style={{
                  width: "115px",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  marginBottom: "-3px",
                }}
                src={require("../chompLogo.png")}
              />
              {/* </a> */}
            </span>
            Influencers and Restaurants,
          </h2>
          <p style={{ fontSize: "18px", marginTop: "15px" }}>
            Enter your unique code to view all postings.
          </p>
          <form onSubmit={(e) => handleSelect(e)}>
            <input
              onChange={(e) => setInviteCodeSubmitted(e.target.value)}
              value={inviteCodeSubmitted}
              placeholder="ABC123"
            />
            <button onClick={(e) => handleSelect(e)}>
              {" "}
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </form>
          <p
            style={{
              display: inviteCodeError ? "inline" : "none",
              color: "#ED4337",
              // marginTop: "-px",
              // marginBottom: "20px",
            }}
          >
            The invite code you entered is not correct, please try again.
          </p>
        </div>
      </div>
      <div className="postsWhole">
        {/* <InfluencerApplyModal /> */}
        {
          // !inviteCodeAccepted &&

          <div>
            {/* <div className="postsIntroForm">
              <h2>Invited Influencers and Restaurants,</h2>
              <p>Please enter your unique code to view all postings.</p>
              <form onSubmit={(e) => handleSelect(e)}>
                <input
                  onChange={(e) => setInviteCodeSubmitted(e.target.value)}
                  value={inviteCodeSubmitted}
                  placeholder="ABC123"
                />
                <button onClick={(e) => handleSelect(e)}>
                  {" "}
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </form>
              <p
                style={{
                  display: inviteCodeError ? "inline" : "none",
                  color: "#ED4337",
                }}
              >
                The invite code you entered is not correct, please try again.
              </p>
            </div> */}
            <p
              style={{
                textAlign: "left",
                width: "86%",
                margin: "auto",
                // marginTop: "25px",
                marginTop: "-10px",
                // marginBottom: "-15px",
                fontSize: "18px",
                marginBottom: "-5px",
                fontWeight: "600",
              }}
            >
              Example Job Postings:
            </p>
            {/* <h2>Example Job Postings:</h2> */}
            <div style={{ marginTop: "30px" }} className="post">
              <div className="postInner">
                <div className="postTitle">
                  <a target="_blank">
                    <h3>Woodbury</h3>
                  </a>
                  <p className="postRestaurantLocation">San Francisco, CA</p>
                </div>
                <p className="postRestaurantDescription">
                  Local, seasonal, and craft beers. Pop In Menu: Special
                  pairings of beer and liqueur to create new and unique flavors.
                  The Beer & Shot bar, featuring various tiers of beer and shot
                  combinations. Our amazing bar bites, including the sinfully
                  awesome LOADED Poutine, and our signature Woodbury Burger with
                  crispy pork belly on top of our delicious patty.
                </p>
                <div className="boo">
                  <div className="postRestaurantImage">
                    <img
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/starfruittwo.appspot.com/o/restaurant_images%2F94ee650f-1298-40c5-be0a-0a32e65b6122?alt=media&token=f25c73fc-f67a-40d9-9541-277bbe91ddfa"
                      }
                    />
                  </div>
                  <div className="postJobDetails">
                    <p className="postCompensation">$100 + Comped Meal</p>
                    <div className="postJobType">
                      <h4>Short Form Video:</h4>
                      <div className="postJobPlatforms">
                        <p className="socialMediaTag tiktokTag">Tiktok</p>
                        <p>and</p>
                        <p className="socialMediaTag reelsTag">Reels</p>
                      </div>
                    </div>
                    <div className="postJobDescription">
                      <h4>Job Description:</h4>
                      <p>
                        You are free to choose any of our dishes for a total of
                        under $40.{" "}
                      </p>
                    </div>
                    <div className="postJobRequirements">
                      <h4>Requirements:</h4>
                      <p>
                        2000+ followers on Tiktok
                        <br />
                        1000+ followers on Instagram
                        <br />
                        Influencer should have a few examples of successful
                        marketing videos.
                      </p>
                      <p></p>
                    </div>
                  </div>
                </div>
                <div
                  onClick={() =>
                    alert(
                      "This is an example posting, please enter your unique code above to view all postings."
                    )
                  }
                  className="postApplyButton"
                >
                  <a>Apply</a>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "30px" }} className="post">
              <div className="postInner">
                <div className="postTitle">
                  <a
                    target="_blank"
                    // href="https://www.yelp.com/biz/tea-and-others-san-francisco"
                  >
                    <h3>Tea & Others</h3>
                  </a>
                  <p className="postRestaurantLocation">San Francisco, CA</p>
                </div>
                <p className="postRestaurantDescription">
                  We serve milk teas, fruit teas, cheese teas (the non-stinky
                  and creamy kind) and cake teas. Occasionally we serve rainbow,
                  and we do actually get it every afternoon in store.
                </p>
                <div className="boo">
                  <div className="postRestaurantImage">
                    <img src="https://firebasestorage.googleapis.com/v0/b/starfruittwo.appspot.com/o/restaurant_images%2Ff9a0ee26-114f-4e04-8d23-c9fb4a1e655c?alt=media&token=2d5e70b2-b239-455f-8a5c-8cd28412b18e" />
                  </div>
                  <div className="postJobDetails">
                    <p className="postCompensation">$100 + Comped Meal</p>
                    <div className="postJobType">
                      <h4>Short Form Video:</h4>
                      <div className="postJobPlatforms">
                        <p className="socialMediaTag tiktokTag">Tiktok</p>
                        {/* <p>and</p>
                      <p className="socialMediaTag reelsTag">Reels</p> */}
                      </div>
                    </div>
                    <div className="postJobDescription">
                      <h4>Job Description:</h4>
                      <p>
                        You may try whatever you want from our store for under
                        $20. We are excited to see what you come up with.
                      </p>
                    </div>
                    <div className="postJobRequirements">
                      <h4>Requirements:</h4>
                      <p>
                        5000+ followers on Tiktok
                        <br />
                        {/* 1000+ followers on Instagram
                      <br /> */}
                        {/* Any other requirements */}
                      </p>
                      <p></p>
                    </div>
                  </div>
                </div>
                <div
                  onClick={() =>
                    alert(
                      "This is an example posting, please enter your unique code above to view all postings."
                    )
                  }
                  className="postApplyButton"
                >
                  <a>Apply</a>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "30px" }} className="post">
              <div className="postInner">
                <div className="postTitle">
                  <a target="_blank">
                    <h3>Acquolina</h3>
                  </a>
                  <p className="postRestaurantLocation">San Francisco, CA</p>
                </div>
                <p className="postRestaurantDescription">
                  San Francisco was in need of authentic Italian fare, thus,
                  Acquolina was born. Run by first-generation Italians, it's
                  only natural that our owners would bring a taste of their
                  Mamma Fausta's flavors across the Golden Gate Bridge. In
                  Italian, Acquolina means "mouthwatering" -- illustrating what
                  happens from the very first taste of our dishes.
                </p>
                <div className="boo">
                  <div className="postRestaurantImage">
                    <img
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/starfruittwo.appspot.com/o/restaurant_images%2F4d79341a-b1a0-40d6-9987-8fd798667061?alt=media&token=a2481fcc-e556-4818-a5dd-c7358fd2834a"
                      }
                    />
                  </div>
                  <div className="postJobDetails">
                    <p className="postCompensation">$50 + Comped Meal</p>
                    <div className="postJobType">
                      <h4>Short Form Video:</h4>
                      <div className="postJobPlatforms">
                        <p className="socialMediaTag tiktokTag">Tiktok</p>
                        {/* <p>and</p>
                      <p className="socialMediaTag reelsTag">Reels</p> */}
                      </div>
                    </div>
                    <div className="postJobDescription">
                      <h4>Job Description:</h4>
                      <p>
                        One of most popular table side dining experiences is our
                        cheese wheel, we would like you should showcase it with
                        you or a party of 2. We also would like you to post
                        about any of our pizzas.
                      </p>
                    </div>
                    <div className="postJobRequirements">
                      <h4>Requirements:</h4>
                      <p>
                        5000+ followers on Tiktok
                        <br />
                        {/* 1000+ followers on Instagram
                      <br />
                      Influencer should have a few examples of successful
                      marketing videos. */}
                      </p>
                      <p></p>
                    </div>
                  </div>
                </div>
                <div
                  onClick={() =>
                    alert(
                      "This is an example posting, please enter your unique code above to view all postings."
                    )
                  }
                  className="postApplyButton"
                >
                  <a>Apply</a>
                </div>
              </div>
            </div>
          </div>
        }
        {/* {inviteCodeAccepted && (
        <div className="jobPosts">
          <h2>Job Postings</h2>
          <div className="posts">
            {jobData &&
              jobData.map((post, index) => {
                return (
                  <div className="post">
                    <div className="postInner">
                      <div className="postTitle">
                        <h3>{post.restaurant_name}</h3>
                        <p className="postRestaurantLocation">
                          {post.restaurant_location}
                        </p>
                      </div>
                      <p className="postRestaurantDescription">
                        {post.restaurant_description}
                      </p>
                      <div className="boo">
                        <div className="postRestaurantImage">
                          <RestaurantImage
                            restaurantImage={post.restaurant_image}
                          />
                        </div>
                        <div className="postJobDetails">
                          <p className="postCompensation">
                            ${post.influencer_compensation} + Comped Meal
                          </p>
                          <div className="postJobType">
                            <h4>{post.media_type}:</h4>
                            <div className="postJobPlatforms">
                              {post.social_media_platforms.includes(
                                "Tiktok"
                              ) ? (
                                <p className="socialMediaTag tiktokTag">
                                  Tiktok
                                </p>
                              ) : (
                                <></>
                              )}
                              {post.social_media_platforms[1] ? (
                                <p>{post.social_media_platforms[1]}</p>
                              ) : (
                                <></>
                              )}
                              {post.social_media_platforms.includes("Reels") ? (
                                <p className="socialMediaTag reelsTag">Reels</p>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <div className="postJobDescription">
                            <h4>Description:</h4>
                            <p>{post.job_description}</p>
                          </div>
                          <div className="postJobRequirements">
                            <h4>Requirements:</h4>
                            <p>
                              {post.tiktok_follower_requirement}+ followers on
                              Tiktok
                              <br />
                              {post.instagram_follower_requirement}+ followers
                              on Instagram
                              <br />
                            </p>
                            <p></p>
                          </div>
                        </div>
                      </div>

                      <div className="postApplyButton">
                        <a target="blank" href={"/application"}>
                          Apply
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )} */}
      </div>
    </div>
  );
}

export default Posts;
