import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { db } from "../firebase-config";
import { useLocation } from "react-router-dom";
import {
  getDocs,
  collection,
  where,
  query,
  doc,
  setDoc,
  addDoc,
} from "firebase/firestore";
import SuccessModal from "./SuccessModal";
import "../styles/SuccessModal.css";
import Post from "../components/Post";

function InfluencerApply(
  {
    // restaurantName,
    // restaurantYelp,
    // restaurantLocation,
    // restaurantDescription,
    // restaurantImage,
    // restaurantInfluencerCompensation,
    // restaurantMediaType,
    // restaurantSocialMediaPlatforms,
    // restaurantJobDescription,
    // tiktokFollowerRequirement,
    // instagramFollowerRequirement,
    // otherInfluencerRequirements,
    // state,
  }
) {
  // const jobPostId = 0;
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [contactEmail, setContactEmail] = useState(null);
  const [tiktokProfile, setTiktokProfile] = useState(null);
  const [instagramProfile, setInstagramProfile] = useState(null);
  const [exampleWork, setExampleWork] = useState(null);
  const [other, setOther] = useState(null);
  const location = useLocation();

  const [jobPostId, setJobPostId] = useState(null);

  const [submitError, setSubmitError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [socialMediaError, setSocialMediaError] = useState(null);

  const [successModalVisible, setSuccessModalVisible] = useState(false);

  useEffect(() => {
    const hmm = window.location.href.substring(
      window.location.href.length - 3,
      window.location.href.length
    );
    setJobPostId(hmm);

    // if (array.includes(hmm)) {
    //   setJobPostId(hmm);
    // }
    // else {
    //   setIsInvitedUser(false);
    // }
    // alert(hmm);
  }, []);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleSelect = async (value) => {
    value.preventDefault();
    if (firstName && lastName && exampleWork && contactEmail) {
      if (tiktokProfile || instagramProfile) {
        if (validateEmail(contactEmail)) {
          const addJobPosting = async () => {
            const docRef = await addDoc(
              collection(db, "influencer_applications"),
              {
                influencer_first_name: firstName,
                influencer_last_name: lastName,
                contact_email: contactEmail,
                influencer_tiktok_profile: tiktokProfile,
                influencer_instagram_profile: instagramProfile,
                example_work: exampleWork,
                other: other,
                job_post_id: jobPostId,
                user_id: location.state.invitedUserCode,
                restaurant_name: location.state.restaurantName,
                time_submitted: Date(Date.now()).toString(),
                time_submitted_unix: Date.now(),
              }
            );
          };
          addJobPosting();
          setSuccessModalVisible(true);
        } else {
          setEmailError(true);
        }
      } else {
        setSocialMediaError(true);
      }
    } else {
      setSubmitError(true);
    }
  };
  return (
    <div className="influencerApply">
      <SuccessModal
        successModalVisible={successModalVisible}
        contactEmail={contactEmail}
        userID={location.state.invitedUserCode}
        restaurantName={location.state.restaurantName}
        successTitle={"Congrats, On Appyling With"}
      />
      <h2>Influencer Application</h2>
      <p className="applicationJobDescription">
        Create a 30 second to 1 minute video
        {/* with voiceover  */} for{" "}
        <h4
          style={{
            display: "inline-block",
            marginBottom: "0px",
            marginTop: "0px",
          }}
        >
          {location.state.restaurantName}
        </h4>
        . You only need to provide your profiles for the relevant social
        platform(s).
      </p>

      <Post
        restaurantName={location.state.restaurantName}
        restaurantYelp={location.state.restaurantYelp}
        restaurantLocation={location.state.restaurantLocation}
        restaurantDescription={location.state.restaurantDescription}
        restaurantImage={location.state.restaurantImage}
        restaurantInfluencerCompensation={
          location.state.restaurantInfluencerCompensation
        }
        restaurantMediaType={location.state.restaurantMediaType}
        restaurantSocialMediaPlatforms={
          location.state.restaurantSocialMediaPlatforms
        }
        restaurantJobDescription={location.state.restaurantJobDescription}
        tiktokFollowerRequirement={location.state.tiktokFollowerRequirement}
        instagramFollowerRequirement={
          location.state.instagramFollowerRequirement
        }
        otherInfluencerRequirements={location.state.otherInfluencerRequirements}
      />
      {/* <p className="applicationJobDescription">
        To create a 30 second to 1 minute stitched video with voiceover
        showcasing [Restaurant Name]. All partnerships include a free meal
        within the restaurant's specified price point.
      </p> */}
      <div className="influencerApplyForm">
        <form>
          {/* <FontAwesomeIcon
            icon={faXmark}
          />{" "} */}

          {/* All partnerships include a free meal within the
            restaurant's specified price point. */}
          <div className="applicationInputs">
            <div className="applicationInputsLeft">
              <h4>First Name *</h4>
              <input
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setSubmitError(false);
                }}
                placeholder="John"
              />
              <h4>Last Name *</h4>
              <input
                onChange={(e) => {
                  setLastName(e.target.value);
                  setSubmitError(false);
                }}
                placeholder="Doe"
              />
              <h4>Contact Email *</h4>
              <input
                onChange={(e) => {
                  setContactEmail(e.target.value);
                  setSubmitError(false);
                  setEmailError(false);
                }}
              />
              {/* <button>Next</button> */}
              {/* <p style={{ marginTop: "0px", marginBottom: "25px" }}>
            Only necessary to provide your profile for the relevant social
            platform(s).
          </p> */}

              <h4>Tiktok Profile</h4>
              <input
                onChange={(e) => {
                  setTiktokProfile(e.target.value);
                  setSubmitError(false);
                  setSocialMediaError(false);
                }}
                placeholder="www.tiktok.com/"
              />
            </div>
            <div className="applicationInputsRight">
              <h4>Instagram Profile</h4>
              <input
                onChange={(e) => {
                  setInstagramProfile(e.target.value);
                  setSubmitError(false);
                  setSocialMediaError(false);
                }}
                placeholder="www.instagram.com/"
              />
              <h4>Example Video of Previous Work (or Example Idea) *</h4>
              <input
                onChange={(e) => {
                  setExampleWork(e.target.value);
                  setSubmitError(false);
                }}
                placeholder="URL"
              />
              <h4>Other</h4>
              {/* <button>Next</button> */}
              <textarea
                onChange={(e) => {
                  setOther(e.target.value);
                  setSubmitError(false);
                }}
                placeholder="Is there anything else you would like to share?"
              ></textarea>
            </div>
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <p
              style={{
                display: submitError ? "inline" : "none",
                color: "#ED4337",
                maxWidth: "none",
              }}
            >
              You may have missed a field, please try again.
            </p>
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <p
              style={{
                display: emailError ? "inline" : "none",
                color: "#ED4337",
                maxWidth: "none",
              }}
            >
              The email you have entered is not valid, please try again.
            </p>
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <p
              style={{
                display: socialMediaError ? "inline" : "none",
                color: "#ED4337",
                maxWidth: "none",
              }}
            >
              Please provide at least one social media profile link and try
              again.
            </p>
          </div>
          <button onClick={(e) => handleSelect(e)}>Submit</button>
        </form>
      </div>
    </div>
  );
}

export default InfluencerApply;
