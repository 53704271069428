import React from "react";
import "../styles/Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";

function Footer() {
  return (
    <div className="footer">
      <div className="footerInnerHi">
        <FontAwesomeIcon className="copyrightIcon" icon={faCopyright} />
        <p>2023 CHOMP</p>
      </div>
      <div className="footerInner">
        <a href="mailto:info@letschomp.com">Email Us</a>
      </div>
    </div>
  );
}

export default Footer;
