import React, { useState } from "react";
import "../styles/Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCirclePlus,
  faAdd,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function Navbar() {
  const navigate = useNavigate();
  const [sideMenuDisplay, setSideMenuDisplay] = useState("none");

  const changeMenu = async (value) => {
    // if (array.includes(inviteCodeSubmitted)) {
    //   setInviteCodeAccepted(true);
    //   window.open("/allposts");
    // } else {
    //   setInviteCodeError(true);
    //   value.preventDefault();
    // }

    if (sideMenuDisplay === "block") {
      setSideMenuDisplay("none");
    } else if (sideMenuDisplay === "none") {
      setSideMenuDisplay("block");
    }
  };

  return (
    // <div
    //   style={{
    //     backgroundColor: "#ff5c2c",
    //     height: "60px",
    //     marginBottom: "0px",
    //   }}
    // >
    //   <div>[Company Name]</div>
    //   <div>
    //     <a>Posts</a>
    //     <a>About</a>
    //     <a>Contact</a>
    //     <a>Blog</a>
    //   </div>
    // </div>
    <nav className="navbar">
      <div className="navbarInner">
        <div className="navLogoDiv">
          <a className="navLogo" href="/">
            <img className="chompLogo" src={require("../chompLogo.png")} />
          </a>
        </div>
        <div className="navResumeDiv">
          <a
            className="navLink"
            // href="#about"z
            onClick={() => navigate(`/`)}
          >
            About
          </a>
          {/* <a className="navLink" onClick={() => navigate(`/`)}>
            Job Posts
          </a> */}
          <a
            className="navLink"
            // href="#requestcode"
            onClick={() => navigate(`/`)}
          >
            Request Code
          </a>
          <div className="addJobButton">
            <a target="blank" onClick={() => navigate(`/list`)}>
              <FontAwesomeIcon
                //   className="copyrightIcon"
                icon={faAdd}
              />{" "}
              Add Job
            </a>
          </div>
          {/* <FontAwesomeIcon
            className="menuIcon"
            style={{
              width: "50px",
              color: sideMenuDisplay === "block" ? "gray" : "white",
              zIndex: "200",
              position: sideMenuDisplay === "block" ? "fixed" : "absolute",
            }}
            onClick={() => changeMenu()}
            icon={faBars}
          />{" "} */}
        </div>
      </div>
      <div
        style={{
          position: "fixed",
          // backgroundColor: "rgb(180, 180, 180)",
          // backgroundImage: "linear-gradient(45deg, #edaa1b 0%,#ff8f2c 100%)",
          backgroundColor: "rgb(225, 225, 225)",
          display: sideMenuDisplay,
          top: 0,
          bottom: 0,
          right: 0,
          width: "170px",
          zIndex: "100",
          paddingTop: "70px",
        }}
      >
        <div
          style={{
            borderTop: ".5px solid gray",
            marginTop: "9px",
          }}
        >
          <span className="navLinkSmallOuter">
            <a className="navLinkSmall" href="#about">
              About
            </a>
          </span>
          <span className="navLinkSmallOuter">
            <a className="navLinkSmall" href="#jobpostings">
              View Job Posts
            </a>
          </span>
          <span className="navLinkSmallOuter">
            <a className="navLinkSmall" href="#requestcode">
              Request Code
            </a>
          </span>
        </div>

        {/* <div className="addJobButton">
          <a target="blank" href={"/list"}>
            <FontAwesomeIcon
              icon={faAdd}
            />{" "}
            Add Job
          </a>
        </div> */}
      </div>
    </nav>
  );
}

export default Navbar;
